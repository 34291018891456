import React, { Component } from "react";
import { withRouter} from 'react-router-dom';
// import Layout from "../../commons/Layout/Layout";
import { Input, Card, Avatar, Row, Col ,Modal} from "antd";
import Buttom from '../../commons/buttonComponent/buttonComponent'
import RenderHtmlText from '../../commons/RenderHtmlText';
import '../styles.css'

const { Search } = Input;

class favoriteArtistComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      artist: [],
      artisOleData: [],
      isSearch:false,
      modal:false,
      onSelect: []
    };
  }
  componentDidMount=async()=>{
   await this.setState({
      artist:this.props.artistData,
      artisOleData:this.props.artistData,
      onSelect:this.props.onSelect,
    })
  }
  selectArtist = (point) =>{
    let unSelectArtist = this.state.artist
    let all = this.state.artisOleData
    let select = this.state.onSelect
    for (let i = 0; i < all.length; i++) {
      if(all[i].id === point.id)
      {
        select.push(all[i])
      }
    }
    for (let i = 0; i < unSelectArtist.length; i++) {
      if(unSelectArtist[i].id === point.id)
    {
        unSelectArtist[i].select=true
      }
    }
    this.setState({
      artist : unSelectArtist,
      isSearch:true,
      onSelect : select
    })
  }
  unSelectArtist = (point) =>{
    let unSelectArtist = this.state.artist
    let all = this.state.onSelect
    let select = []
    for (let index = 0; index < all.length; index++) {
      if(all[index].id !== point.id)
      {
        select.push(all[index])
      }
    }
    for (let i = 0; i < unSelectArtist.length; i++) {
      if(unSelectArtist[i].id === point.id)
    {
        unSelectArtist[i].select=false
      }
    }
    this.setState({
      artist : unSelectArtist,
      isSearch:true,
      onSelect : select
    })
  }
  handleSearch = (text) =>{
    if(text===''){
        this.setState({artist:this.state.artisOleData})
    }else{
        text = text.substr(0,20);
        let data = this.state.artist.filter((data)=>{
            return data.name.toLowerCase().indexOf(text.toLowerCase()) !== -1
        })
        var results = [];

        results = data.map((content)=>{
            return content
        })
        this.setState({artist:results})
    }
}
  render() {
    const { back } = this.props
    const { artist,onSelect } = this.state;
    const cardPoint = artist.map(point => (
      <Col xs={12} key={point.id}>
        <Card className="card-select">
          <div onClick={()=>point.select===false?this.selectArtist(point):this.unSelectArtist(point)}>
            <Avatar style={{
            border:point.select===true?'4px solid #45E2D9':'4px solid transparent',
            }} size={110} src={point.image} />
          </div>
          <Row  type='flex' align='middle' justify='center'
                style={{paddingTop:5}}  
                onClick={()=>this.setState({
                  artistDetail : point.detail,
                  modal: true
                })}>
              <div><span>{point.name&&point.name.length > 12 ?(point.name).substring(0, 12)+"..." : point.name}</span></div>
              <p style={{paddingTop:15,paddingLeft:5}} className="material-icons">info</p>
          </Row>
        </Card>
      </Col>
    )) 
    return (
        <div>
            <div className='fav-wrapper'>
                <div className='fav-title'>
                    <h1>ศิลปินที่ชอบ</h1>
                </div>
                <div className='fav-search'>
                    <Search
                        onChange = {e =>this.handleSearch(e.target.value)}
                        placeholder="Search หาชื่อศิลปิน"
                        enterButton
                    />
                </div>
                <div className="fav-tap-wrapper" >
                    <div className='artist-name-card'>
                        <Row>{cardPoint}</Row>
                    </div>
                </div>
                <div style={{marginTop:20}}>
                <hr width="100%" size="20" color='#F4F4F4' />
                </div>
                <div className='fav-buttom'>
                    <Buttom 
                      padding ='15px 0px 0px 0px'
                      marginBottom='15px' 
                      marginRight='auto' 
                      marginLeft='auto' 
                      block  
                      fixed 
                      onClick={()=>back(onSelect)}>บันทึก</Buttom>
                </div>
            </div>
            <Modal
              className='sendcode-modal modal-dialog-scrollable'
              // style={{height:600}}
              centered
              visible={this.state.modal}
              // onOk={this.handleOk}
              // maskClosable={false}
              onCancel={()=>this.setState({modal:false})}
              footer={[]}
              >
                  <h1 className='text-center'><b>รายละเอียดศิลปิน</b></h1>
                  <div className='text-left modal-body-content'>
                    <RenderHtmlText content={this.state.artistDetail} />
                  </div>
              </Modal>
        </div>
    );
  }
}
export default withRouter(favoriteArtistComponent);
