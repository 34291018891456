import React, { Component } from "react";
import { withRouter} from 'react-router-dom';
import { Card, Avatar, Row, Col, Icon } from "antd";
import "./styles.css";
/* redux */
import * as ACTION from "../../store/actions/action";
import { connect } from "react-redux";
/* redux */
// import { httpClient } from "../../utils/HttpClient";
class profileDetailComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:true,
      fullname: '-',
      image: '/userDf.png',
      gender: 4,
      birthdate: '-',
      email: '-',
      telephone: '-',
      province: '-',
      customers_miles: 0,
      profileLoading:false
      }
    }
  componentDidMount = ()=>{
    }
  render() {
    let {image,fullname,birthdate,gender,email,telephone,province,customers_points} = this.props
    return (
      <div className="profile-detail">
        <div className="card-form">
          <div className="avatar__user">
            <Avatar
              className="avatar-user"
              size={150}
              src={
                image ? image : this.state.image
              }
            />
            <span className="edit-icon" onClick={()=>this.props.history.push('/profile/edit')}>
              <i className="material-icons">edit</i>
            </span>
          </div>
          <div className="point-material">
            <div className="point-icon">
              <i className="material-icons">star</i>
            </div>
            <h4>{customers_points ? customers_points : 0} Points</h4>
          </div>
          <Card className="detail-card">
            <div className="detail-box">
              <Row type="flex" justify="center" align="middle">
                <Col xs={24}>
                  <div className="detail-text">
                    <i className="material-icons">account_circle</i>
                    <h4>{fullname?fullname:'-'}</h4>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="detail-text">
                    <Icon style={{fontSize:20}} type="man" />
                    <h4> {gender === 1 ? 'เพศชาย' : gender === 2 ? 'เพศหญิง' : gender === 3 ? 'ไม่ระบุเพศ' : "-" }</h4>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="detail-text">
                    <i className="material-icons">calendar_today</i>
                    <h4>{birthdate? birthdate:"-"}</h4>
                  </div>
                </Col>
                <Col xs={24}>
                  <div className="detail-text">
                    <i className="material-icons">drafts</i>
                    <h4>{email?email:'-'}</h4>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="detail-text">
                    <i className="material-icons">phone</i>
                    <h4>{telephone?telephone:'-'}</h4>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="detail-text">
                    <i className="material-icons">place</i>
                    <h4>{province?province:"-"}</h4>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
// export default withRouter(profileDetailComponent)
function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading
  };
}
function mapDispatchToProps(dispatch) {
return {
  isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
  isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
  isPoint: (point) => dispatch(ACTION.isPoint(point))
};
}
export default connect(
mapStateToProps,
mapDispatchToProps
)(withRouter(profileDetailComponent));