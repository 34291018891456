/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
// import DOMPurify from 'isomorphic-dompurify';

const RenderHtmlText = ({ content, className }) => {
  return (
    <div
      className={className}
    >
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: content
        }}
      />
    </div>
  );
};

RenderHtmlText.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string
};

RenderHtmlText.defaultProps = {
  className: ''
};

export default RenderHtmlText;
