import React, { Component } from 'react'
import Layout from "../../commons/Layout/Layout";
import {
    Card,Carousel,Radio,Spin, Empty, Icon
    // message
  } from "antd";
import './styles.css'
/* redux */
import * as ACTION from "../../../store/actions/action";
import { connect } from "react-redux";
/* redux */
import { httpClient } from "../../../utils/HttpClient";
import InfiniteScroll from 'react-infinite-scroller';

class pointAllProductComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rewardData:[],
            slice:[],
            slicePage:0,
            recordsTotal:0,
            loading: false,
            hasMore: true,
            start:3
        }
        this.crousel = React.createRef();
    }
    componentDidMount = () =>{
        this.props.isLoadingActive()
        let back = (data) => {
            this.props.isPoint(data.data.customers_points.points)
        }
        httpClient.get('frontend/api/points/get').then(doc=>{
                back(doc.data);
        }).catch(err=>{
            throw err
        })
        this.fetchData()
    }
    fetchData = async() =>{
        let bannerCount = [];
        let setBannerData = (bannerData) => {
            this.setState({ slice: bannerData });
        };
        let BannerFecth = httpClient
        .get("frontend/api/dashboard_c/banner")
        .then((doc) => {
            let count = doc.data.data;
            count.forEach(doc=>{
                bannerCount.push(
                    Object.assign({
                        index : doc.index,
                        id:doc.event.id,
                        image:doc.event.image_m,
                        name:doc.event.name,
                        points:doc.event.points,
                        start_date:doc.event.events_details.start_date,
                        end_date:doc.event.events_details.end_date
                    })
                )
            })
            setBannerData(bannerCount)
        })
        .catch((err) => {
            throw err;
        });
        let rewardData = [];
        let setRewardData = (rewardData,recordsTotal) => {
            this.setState({ rewardData, walletLoading: false,recordsTotal });
        };
        let RewardFecth = httpClient
        .post("frontend/api/dashboard_c/voucher",{
            length:3,
            start:0
        })
        .then((doc) => {
            let count = doc.data.data;
            count.forEach(doc=>{
            rewardData.push(doc)
            })
            setRewardData(rewardData,doc.data.recordsTotal)
        })
        .catch((err) => {
            throw err;
        });
        await Promise.all([BannerFecth,RewardFecth]);
        await this.props.isLoadingDeActive()
    }
    onSliceChange=(e)=> {
        this.setState({slicePage:e})
        this.crousel.goTo(+e);
    }
    onChange = e => {
        this.crousel.goTo(+e.target.value);
        this.setState({
            slicePage: e.target.value,
        });
      };
    loadmoreReward = () =>{
        let { rewardData,recordsTotal,start } = this.state;
        let setRewardData = (pointData) => {
            this.setState({ rewardData:pointData, start : start + 3 ,loading: false});
        };
        this.setState({
            loading: true,
        });
        if (rewardData.length >= recordsTotal) {
            //message.warning('Finished loading!');
            this.setState({
            hasMore: false,
            loading: false,
            });
            return;
        }
        httpClient
        .post("frontend/api/dashboard_c/voucher",{
            length:3,
            start:start
        })
        .then((doc) => {
            let count = doc.data.data;
            count.forEach(doc=>{
                rewardData.push(doc)
            })
            setRewardData(rewardData)
        })
        .catch((err) => {
            throw err;
        });
    }
    render() {
        const {rewardData,slice} = this.state
        const slicePoint = slice.map((item)=> 
            (
                <div className='card-reward' style={{borderRadius: '5%'}} onClick={()=> this.props.history.push(
                    {
                        pathname: '/reward/point-to-privilege',
                        state:{
                            id:item.id,
                        }
                    })} key={item.index} >
                    <div className='slice-img'><img className="slice-card-img" src={item.image} alt="logo" /></div>
                    <div className='mt-1'>
                    <div className='slice-text'><span>{item.name} (ใช้ {item.points} Points)</span></div>
                    <div className='slice-point'><span>{item.start_date === item.end_date ? item.end_date :`${item.start_date} - ${item.end_date}`}</span></div>
                    </div>
                </div>
            )
        )
        const sliceRadio = slice.map((item,value)=> 
            (
                <Radio key={`sliceRadio-${value}`} value={value}></Radio>
            )
        )
        const cardPoint = rewardData.map(item=> 
            (
                <div className='card-reward' key={item} onClick={()=>this.props.history.push(
                    {
                        pathname: '/reward/point-to-privilege',
                        state:{
                            id:item.id,
                        }
                    })}>
                    <Card style={{backgroundColor:'transparent'}}>
                       <img className="reward-card-img" src={item.image_m} alt="logo" />
                    </Card>
                    <div className='point-text' style={{backgroundColor:'#00625D'}}>
                        <h4>{item.points} Points</h4>
                    </div>
                    <div className='point-card-text'><span>{item.name}</span></div>
                    <div className='point-card-point'><span>{item.events_details.start_date === item.events_details.end_date ? item.events_details.end_date :`${item.events_details.start_date} - ${item.events_details.end_date}`}</span></div>
                </div>
            )
        )
        return (
            <div className='demo-infinite-container'>
            <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        loadMore={this.loadmoreReward}
                        hasMore={!this.state.loading && this.state.hasMore}
                        useWindow={false}
                    >
            <Layout>
                <div className='all-point-wrapper'>
                    <div className="point-title">
                        {slicePoint.length > 0 || cardPoint.length > 0 ? <span>ใช้ Point ลุ้นรับรางวัล</span> : <span>ขณะนี้ยังไม่มีรางวัลให้แลก...</span>}
                    </div>
                    {slicePoint.length > 0 || cardPoint.length > 0 ?
                        <div className='mt-1'>
                            <div style={{padding:20}}>
                                <Card className='slice-card' >
                                    <Carousel 
                                        style={{borderRadius: '5%'}}
                                        ref={node => {this.crousel = node;}} 
                                        dots={false} 
                                        afterChange={this.onSliceChange.bind()} 
                                        // autoplay
                                    >
                                        {slicePoint}
                                    </Carousel>       
                                </Card>
                            </div>
                            <div style={{textAlign:'center'}}>
                                <Radio.Group onChange={this.onChange} value={this.state.slicePage}>
                                    {sliceRadio}
                                </Radio.Group>
                            </div>
                            <div className="mt-1">
                                <div style={{padding:"30px 20px 20px 20px"}}>
                                    {cardPoint}
                                </div>
                                {this.state.loading && this.state.hasMore && (
                                    <div className="demo-loading-container">
                                        <Spin />
                                    </div>
                                )}
                            </div>
                        </div>
                    : (
                        <Empty
                            imageStyle={{
                                height: 60,
                            }}
                            description={
                                <span style={{ color : 'black'}}>
                                    ขณะนี้ยังไม่มีรางวัลให้แลก...
                                </span>
                                } image={<Icon style={{ fontSize: '3.5rem', color: '#08c' }} type="inbox" />}
                        />
                    )}
                </div>
            </Layout>
        </InfiniteScroll>
    </div>
        )
    }
}
function mapStateToProps(state) {
    return {
      isLoading: state.loading.isLoading
    };
  }
  function mapDispatchToProps(dispatch) {
  return {
    isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
    isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
    isPoint: (point) => dispatch(ACTION.isPoint(point))
  };
  }
  export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(pointAllProductComponent);