import * as ACTION_TYPES from '../actions/action_type'
const initialState = {
    point : null
}

const isPointReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.ISPOINT:
            const point = action.payload
                return {
                    ...state,
                    point: point
                }
        default:
            return state
    }
}

export default isPointReducer