import React, { Component,Suspense } from 'react'
import history from './utils/history'
import {
  Spin
} from "antd";
import { Router, Route, Switch 
  ,Redirect
 } from 'react-router-dom'
import { connect } from "react-redux";
import {
  RegisterComponent,
  RegisterSuccessComponent,
  ProfileComponent,
  PointAllProductComponent,
  PointToPrivilegeComponent,
  UsePrivilegeComponent,
  AddPrivilegeComponent,
  AddEventPointComponent,
  QrReaderComponent,
  EditFavoriteArtistComponent,
  UsePrivilegeNowComponent,
  EditProfileComponent,
  ContactComponent,
  Notfound
} from './components'

  const SecuredRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        (localStorage.getItem('userData')) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
  // const LoginRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={props =>
  //       (!localStorage.getItem('userData'))? (
  //         <Component {...props} />
  //       ) : (
  //         <Redirect to="/dashboard" />
  //       )
  //     }
  //   />
  // );
  // Protected Route
class Routes extends Component {
    constructor() {
        super()
        this.state = {
            authenticated: false,
        }
    }
    render() {
      // localStorage.getItem("userData")&&console.log('token','Bearer '+JSON.parse(localStorage.getItem("userData")).token)
        return (
            <div>
            <Suspense fallback={null}>
            <Spin size="large" spinning={this.props.isLoading} tip="กำลังโหลด...">
            <Router history={history}>
                <Switch>
                  <Route exact path="/" component={RegisterComponent} />
                  <SecuredRoute exact path="/profile/register-success" component={RegisterSuccessComponent} />
                  <SecuredRoute exact path="/profile" component={ProfileComponent} />
                  <SecuredRoute exact path="/profile/edit" component={EditProfileComponent} />
                  <SecuredRoute exact path="/profile/edit-artist" component={EditFavoriteArtistComponent} />
                  <SecuredRoute exact path="/reward" component={PointAllProductComponent} />
                  <SecuredRoute exact path="/reward/point-to-privilege" component={PointToPrivilegeComponent} /> 
                  <SecuredRoute exact path="/reward/use-privilege" component={UsePrivilegeComponent} /> 
                  <SecuredRoute exact path="/point/add-point" component={AddPrivilegeComponent} /> 
                  <SecuredRoute exact path="/point/:pointId/add" component={AddEventPointComponent} /> 
                  <SecuredRoute exact path="/point/qr" component={QrReaderComponent} />
                  <SecuredRoute exact path="/reward/use-privilege-now" component={UsePrivilegeNowComponent} />
                  <Route path='/contact' component={ContactComponent}/>
                  <Route component={Notfound} />
                </Switch>
            </Router>
            </Spin>
            </Suspense>
            </div>
        )
    }
}
function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading
  }
}
  
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);