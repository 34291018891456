import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu, Drawer, 
    // Button
  } from 'antd';
import './styles.css'
/* redux */
import * as ACTION from "../../../store/actions/action";
import { connect } from "react-redux";
/* redux */
const { Header } = Layout;
class Layouts extends Component {
    constructor() {
        super()
        this.state = {
            visible: false,
            user:""
        }
    }
    showDrawer = () => {
        this.setState({
            visible: true,
        })
    }
    onClose = () => {
        this.setState({
            visible: false,
        })
    }
    render() {
        let paths = window.location.pathname
        const host = paths.split("/")[1]
        const routes = [
            { path: '/profile', exact: true, key: 'profile', label: 'ข้อมูลส่วนตัว', },
            { path: '/reward', key: 'reward', label: 'แลกของรางวัล', },
            { path: '/point/add-point', key: 'point', label: 'สะสมแต้ม', },
            { path: '/contact', exact: true, key: 'contact', label: 'ติดต่อเรา', },
        ]
        return (
      <Layout>
            <Header className='header-wrap' style={{ background: '#01A69D', padding: 0 }}>
                <div>
                    {paths !== "/"&& paths !== "/reward/use-privilege" ?<div className='menu-button' onClick={this.showDrawer}><i className="material-icons">
                        menu
                    </i></div>:""}
                    <img className="img-center" src='/logo.png' alt="logo" />
                    {host === "point" ||host === "reward"?<div className='point-button'>
                        <div className="point-material">
                            <div className="point-icon">
                            <i className="material-icons">star</i>
                            </div>
                            <h4>{this.props.pointStore ? this.props.pointStore : 0} Points</h4>
                        </div>
                    </div>:""}
                </div>
            </Header>
            <Drawer
                bodyStyle={{padding:0,paddingTop:20}}
                placement="left"
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
            >
                <Menu
                className="menu-box"
                    selectedKeys={[
                        paths === "/profile" || host === "profile"? "profile" 
                        : paths === "/reward" || host === "reward" ? "reward"
                        : paths === "/point" || host === "point" ? "point" 
                        : paths === "/contact" || host === "contact" ? "contact": ' '
                    ]}
                >
                    { routes.map( (item) => {
                        return (
                            <Menu.Item key={item.key}>
                                <Link className="link-menu" to={item.path}>
                                    <span>{item.label}</span>
                                </Link>
                            </Menu.Item>
                        )
                    } ) }
                </Menu>
                <div className='logo-buttom'><img className="img-menu" src='/logo.png' alt="logo"/></div>
            </Drawer>
          <div>
            {this.props.children}
          </div>
      </Layout>
    );
  }
}
function mapStateToProps(state) {
    return {
      pointStore: state.point.point
    };
  }
  function mapDispatchToProps(dispatch) {
  return {
    isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
    isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
    isPoint: () => dispatch(ACTION.isPoint)
  };
  }
  export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(Layouts);