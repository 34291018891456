import React, { Component } from 'react'
import Layout from "../../commons/Layout/Layout";
import Button from '../../commons/buttonComponent/buttonComponent'
import {
    Input,
    Modal
  } from "antd";
import { httpClient } from "../../../utils/HttpClient";
import Swal from 'sweetalert2' 
/* redux */
import * as ACTION from "../../../store/actions/action";
import { connect } from "react-redux";
/* redux */

class addEventPointComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pointId : this.props.match.params.pointId,
            visible:false,
            point:""
        }
    }
    componentDidMount(){
        let back = (message) => {
            this.setState({
                visible : true,
                point:message
            })
        }
        let error = () =>{
            this.props.history.push('/point/add-point')
        }
        Swal.fire({
            title: "กำลังตรวจสอบโค้ด",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            }
          });
        httpClient.post('frontend/api/scan/code',
            {
                code:this.state.pointId
            }).then(doc=>{
                Swal.close()
                back(doc.data.message);
        }).catch(err=>{
            error()
        })
    }
    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    handleOk = () => {
        setTimeout(() => {
            this.props.history.push('/point/add-point')
        }, 2000)
    };
    handleCancel = e => {
        this.setState({
            visible: false
        });
    };
    render() {
        return (
                <Layout>
                    <div className='add-point-wrapper'>
                        <h3>กรอกรหัสของคุณ</h3>
                        <div className='add-point-input'>
                        <Input 
                            placeholder='xxxxxxxx'
                            size="large"
                            name="code"
                            minLength={3}
                            value={this.state.pointId}
                            type="text" 
                            onChange={e=>this.setState({code: e.target.value})}/>
                        </div>
                        <div className='add-point-button'>
                            <Button marginRight='auto' marginLeft='auto' width='100%' onClick={()=>this.setState({visible:true})} block>
                                สะสม Point
                            </Button>
                            <Button marginRight='auto' marginLeft='auto' width='100%' color="#01A69D" bgColor="transparent" onClick={()=>this.scanCode()} block>Scan Qr code</Button>
                        </div>
                    </div>
                    <div>
                        <Modal
                        style={{textAlign:'center'}}
                        centered
                        maskClosable={false}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                                <Button marginLeft='auto' marginRight='auto'marginBottom='20px' block key='ok' onClick={this.handleOk}>
                                    ตกลง
                                </Button>
                        ]}
                        >
                            <h1 className='text-center' style={{marginTop:30,fontSize:24,fontWeight:500}}>{this.state.point}</h1>
                        </Modal>
                    </div>
                </Layout>
        )
    }
}
function mapStateToProps(state) {
    return {
      isLoading: state.loading.isLoading
    };
  }
  function mapDispatchToProps(dispatch) {
  return {
    isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
    isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
    isPoint: (point) => dispatch(ACTION.isPoint(point))
  };
  }
  export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(addEventPointComponent);