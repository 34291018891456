import React, { Component } from 'react'
import Layout from "../../commons/Layout/Layout";
import Button from '../../commons/buttonComponent/buttonComponent'
import {
    Input
  } from "antd";
import Swal from 'sweetalert2'
/* redux */
import * as ACTION from "../../../store/actions/action";
import { connect } from "react-redux";
/* redux */
import { httpClient } from "../../../utils/HttpClient";
const liff = window.liff;
class addPointComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            code: '',
            visible : false
        }
    }
   async componentDidMount(){
        await liff.init({ liffId: `${process.env.REACT_APP_LINE_ID}`})
          if (liff.isLoggedIn()) {
            let back = (data) => {
                this.props.isPoint(data.data.customers_points.points)
            }
            let getProfile = await liff.getProfile();
            let data = Object.assign({
                userId: getProfile.userId,
                imageUrl:getProfile.pictureUrl,
                email :liff.getDecodedIDToken().email?liff.getDecodedIDToken().email:'',
                token: liff.getAccessToken(),
            })
            await localStorage.setItem("userData",JSON.stringify(data));
            await localStorage.setItem("token",liff.getAccessToken());
            let params = new URLSearchParams(window.location.search);
            let PointId = params.get('pointId');
            if(PointId){
                await this.props.history.push(`/point/${PointId}/add`)
            }else{
              await httpClient.get('frontend/api/points/get').then(doc=>{
                back(doc.data);
              }).catch(err=>{
                  throw err
              })
            }
        }
    }
    handleOk = () => {
        if (this.state.code !== '') {
            this.props.history.push(`/point/${this.state.code}/add`)
        }
        Swal.fire(
            'กรอกหรือแสกนโค้ดเพื่อรับรางวัล',
            '',
            'error'
          )
    };
    scanCode=()=> {
       window.location='https://line.me/R/nv/QRCodeReader'
      }
    handleCancel = e => {
        this.setState({
            visible: false
        });
    };
    checkIOS = () =>{
        this.scanCode()
    }
    render() {
        return (
                <Layout>
                    <div className='add-point-wrapper'>
                        <h3>กรอกรหัสของคุณ</h3>
                        <div className='add-point-input'>
                        <Input 
                            placeholder='xxxxxxxx'
                            size="large"
                            name="code"
                            minLength={3}
                            type="text"
                            value={this.state.code} 
                            onChange={e=>this.setState({code: e.target.value})}/>
                        </div>
                        <div className='add-point-button'>
                            <Button marginRight='auto' marginLeft='auto' width='100%' onClick={this.handleOk} block>
                                สะสม Point
                            </Button>
                            <Button marginRight='auto' marginLeft='auto' width='100%' color="#01A69D" bgColor="transparent" onClick={()=>this.checkIOS()} block>Scan Qr code</Button>
                        </div>
                    </div>
                </Layout>
        )
    }
}
function mapStateToProps(state) {
    return {
      isLoading: state.loading.isLoading
    };
  }
  function mapDispatchToProps(dispatch) {
  return {
    isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
    isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
    isPoint: (point) => dispatch(ACTION.isPoint(point))
  };
  }
  export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(addPointComponent);
