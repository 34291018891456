import React, { Component } from 'react'
// import Layout from "../commons/Layout/Layout";
// import ReactDOM from 'react-dom'

class Notfound extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        // this.scanCode = this.scanCode.bind(this)
    }
    render() {
        return (
                // <Layout>
                        <h3 className='text-center'>404 NOT FOUND</h3>
                // </Layout>
        )
    }
}
export default Notfound
