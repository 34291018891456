import LoadingReducer from "./loading";
import Point from "./point.reducers.js"
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  /* use */
  point: Point,
  loading: LoadingReducer
  /* use */
});

export default rootReducer;
