import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, Avatar, Col, Row } from "antd";
/* redux */
import * as ACTION from "../../store/actions/action";
import { connect } from "react-redux";
/* redux */
import { httpClient } from "../../utils/HttpClient";
class favoriteArtistComponant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      artisData: [
        {
          key: 1,
          detail: "",
          image: "",
        },
      ],
    };
  }
  componentDidMount = () => {
    let artistCount = [];
    let setArtistData = (artisData) => {
      this.setState({ artisData, artistLoading: false });
    };
    httpClient
      .post("frontend/api/profile_c/artists", {
        length: 8,
        search: "",
      })
      .then((doc) => {
        let i = 0;
        let data = doc.data.data;
        data.forEach((doc) => {
          i = i + 1;
          artistCount.push(
            Object.assign({
              key: i,
              detail: doc.detail,
              image: doc.image,
            })
          );
        });
        setArtistData(artistCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { artisData } = this.state;
    const avtarImg = artisData.map((avatar) => (
      <Col xs={3} key={avatar.key}>
        {avatar.key !== 8 ? (
          <Avatar
            key={avatar.key}
            className="avatar-user"
            size="large"
            src={avatar.image}
          />
        ) : (
          <Avatar
            key={avatar.key}
            // style={{opacity:0.4,filter:'alpha(opacity=40)'}}
            className="avatar-user"
            size="large"
            src={avatar.image}
          />
        )}
      </Col>
    ));
    return (
      <div>
        <Card className="artist-card">
          <div className="artist-card-text">
            <h4 onClick={() => this.props.history.push("/profile/edit-artist")}>
              {artisData === 0 ? "เพิ่มศิลปินที่ชื่นชอบ" : "ศิลปินที่ชื่นชอบ"}
            </h4>
          </div>
          <div
            className="artist-list"
            onClick={() => this.props.history.push("/profile/edit-artist")}
          >
            <Row>{avtarImg}</Row>
          </div>
        </Card>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
    isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(favoriteArtistComponant));
