import React, { Component } from "react";
import Layout from "../../commons/Layout/Layout";
import {
  Row,
  Col,
  message,
  Upload,
  Card,
  Input,
  Form,
  Avatar,
  Checkbox,
  Select,
  Modal,
} from "antd";
import ImageResize from "image-resize";
import Button from "../../commons/buttonComponent/buttonComponent";
import Swal from "sweetalert2";
import "../styles.css";
import "../../../stylesheet/main.css";
/* redux */
import * as ACTION from "../../../store/actions/action";
import { connect } from "react-redux";
/* redux */
import { httpClient } from "../../../utils/HttpClient";
import FavArtist from "./favoriteArtistComponent";
import moment from "moment";
import heic2any from "heic2any";
import Countdown from "react-countdown";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
moment.locale("th");
const { Option } = Select;
const liff = window.liff;
const imageResize = new ImageResize({
  format: "jpg",
  quality: 0.5,
  width: 500,
});
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/heic" ||
    file.type === "image/heif" ||
    file.type === "image/heif-sequence" ||
    file.type === "image/heic-sequence";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    Swal.fire("ขนาดรูปภาพต้องไม่เกิน 10 mb", "", "error");
  }
  return isJpgOrPng && isLt10M;
}
class registerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRegisterPage: true,
      gender: "1",
      visible: false,
      dateType: "text",
      imageUrl: "/userDf.png",
      name: "",
      born: "536432400",
      borntext: "1987-01-01",
      tel: "",
      email: "",
      artis: [],
      artisSelect: [],
      place: "",
      bornPlacehoder: "วันเกิด*",
      cityData: [],
      amphurData: [],
      districtData: [],
      image_default: 1,
      artisData: [],
      checkbox: false,
      otp: "",
      artisOleData: [],
      loginModal: false,
      dataFalse: true,
      check: false,
      onSelect: [],
      imageFile: [],
      isCount: false,
      count: 60,
      visibleSubmit: false,
    };
    this.renderers = this.renderers.bind();
  }
  componentDidMount = () => {
    this.props.isLoadingActive();
    this.fecthData();
  };
  fecthData = async () => {
    await liff.init({ liffId: `${process.env.REACT_APP_LINE_ID}` });
    if (liff.isLoggedIn()) {
      let getProfile = await liff.getProfile();
      let data = Object.assign({
        userId: getProfile.userId,
        imageUrl: getProfile.pictureUrl,
        email: liff.getDecodedIDToken().email
          ? liff.getDecodedIDToken().email
          : "",
        token: liff.getAccessToken(),
      });
      localStorage.setItem("userData", JSON.stringify(data));
      localStorage.setItem("token", liff.getAccessToken());
      let setData = (imageUrl, email) => {
        this.setState({ imageUrl, email });
      };
      let setArtistData = (artisData) => {
        this.setState({ artisData, artisOleData: artisData });
      };
      setData(data.imageUrl, data.email);
      let setCityData = (data) => {
        this.setState({ cityData: data });
      };
      let checkUser = (data) => {
        data.status === true
          ? this.props.history.push("/profile")
          : this.setState({
              visible: true,
              text: "คุณยังไม่ได้สมัครสมาชิก Spicy Club",
              status: data.status,
              check: true,
            });
      };
      await httpClient
        .get("frontend/api/register/check")
        .then((doc) => {
          checkUser(doc.data);
        })
        .catch((err) => {
          throw err;
        });
      await this.props.isLoadingDeActive();
      let provincesFecth = await httpClient
        .get("frontend/api/address/provinces")
        .then((doc) => {
          setCityData(doc.data.data);
        })
        .catch((err) => {
          throw err;
        });
      let artistsFecth = await httpClient
        .post("frontend/api/artists/all", {
          search: "",
        })
        .then((doc) => {
          let artisData = [];
          let data = doc.data.data;
          data.forEach((doc) => {
            artisData.push(
              Object.assign({
                detail: doc.detail,
                id: doc.id,
                image: doc.image,
                name: doc.name,
                select: false,
              })
            );
          });
          setArtistData(artisData);
        })
        .catch((err) => {
          throw err;
        });
      await Promise.all([provincesFecth, artistsFecth]);
    } else {
      this.setState({
        loginModal: true,
      });
    }
    await this.props.isLoadingDeActive();
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  handleChangePhoto = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      this.props.isLoadingActive();
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        let setData = (url, image) => {
          this.setState({
            imageUrl: url,
            loading: false,
            imgFile: image,
            image_default: 0,
            imageFile: info.fileList,
          });
          this.props.isLoadingDeActive();
        };
        info.file.type === "image/jpeg" || info.file.type === "image/png"
          ? imageResize
              .play(imageUrl)
              .then(async function (responses) {
                let img = dataURLtoFile(responses, `${Date.now()}.jpg`);
                setData(imageUrl, img);
              })
              .catch(function (error) {
                console.error(error);
              })
          : fetch(imageUrl)
              .then((res) => res.blob())
              .then((blob) =>
                heic2any({
                  blob,
                  toType: "image/jpeg",
                  quality: 0.3,
                  multiple: true,
                })
              )
              .then((conversionResult) => {
                conversionResult.forEach((image) => {
                  var url = URL.createObjectURL(image);
                  imageResize
                    .play(url)
                    .then(async function (responses) {
                      let file = dataURLtoFile(responses, `${Date.now()}.jpg`);
                      setData(url, file);
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                });
              });
      });
    }
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = (e) => {
    this.setState({
      visible: false,
      visibleSubmit: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      visibleSubmit: false,
      notiModal: false,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (this.state.checkbox === true) {
          Swal.fire({
            title: "กำลังส่ง OTP ไปยังมือถือ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            },
          });
          let setData = (ref, otp) => {
            this.setState({
              ref,
              otp,
              visibleSubmit: true,
            });
            Swal.close();
          };
          await httpClient
            .post("frontend/api/register/send/otp", {
              telephone: this.state.tel,
            })
            .then((doc) => {
              setData(doc.data.ref, doc.data.otp);
            })
            .catch((err) => {
              throw err;
            });
        } else {
          Swal.fire("กรุณายอมรับเงื่อนไขข้อตกลงของเรา", "", "error");
        }
      } else {
        Swal.close();
        console.log("error: ", err);
        err.name
          ? this.setState({ isName: "close" })
          : this.setState({ isName: "check" });
        err.born
          ? this.setState({ isBorn: "close" })
          : this.setState({ isBorn: "check" });
        err.tel
          ? this.setState({ isTel: "close" })
          : this.setState({ isTel: "check" });
        err.email
          ? this.setState({ isEmail: "close" })
          : this.setState({ isEmail: "check" });
        err.city
          ? this.setState({ isCity: "close" })
          : this.setState({ isCity: "check" });
        err.artis
          ? this.setState({ isArtis: "close" })
          : this.setState({ isArtis: "check" });
        err.place
          ? this.setState({ isPace: "close" })
          : this.setState({ isPace: "check" });
        err.postNum
          ? this.setState({ isPostNum: "close" })
          : this.setState({ isPostNum: "check" });
      }
    });
  };
  success = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "บันทึกข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let back = () => {
      window.location = "/profile/register-success";
    };
    if (this.state.otp !== "") {
      let formData = new FormData();
      formData.append("fullname", this.state.name);
      formData.append("gender", +this.state.gender);
      formData.append("birthdate", this.state.born);
      formData.append("telephone", this.state.tel);
      formData.append("address", this.state.place);
      formData.append("province_id", this.state.city);
      formData.append("amphure_id", this.state.amphur);
      formData.append("district_id", this.state.district);
      formData.append("email", this.state.email);
      let artis = this.state.artisSelect;
      for (let i = 0; i < artis.length; i++) {
        formData.append("artists[]", +artis[i].id);
      }
      formData.append("otp", +this.state.otp);
      formData.append("ref", this.state.ref);
      this.state.image_default === 1
        ? formData.append("image_url", this.state.imageUrl)
        : formData.append("image", this.state.imgFile);
      formData.append("image_default", this.state.image_default);
      formData.append(
        "lineId",
        JSON.parse(localStorage.getItem("userData")).userId
      );
      await httpClient
        .post("frontend/api/register", formData)
        .then((result) => {
          Swal.fire({
            title: "สมัครสมาชิกสำเร็จ!",
            type: "success",
            text: "ระบบกำลังเปลี่ยนหน้า",
            timer: 1500,
          }).then(() => {
            back();
          });
        })
        .catch((err) => {
          throw err;
        });
    } else {
      Swal.fire({
        title: "กรุณากรอกรหัสที่ได้จากโทรศัพท์มือถือ!",
        type: "error",
      });
    }
  };
  handleFocus = () => {
    this.setState({
      dateType: "date",
    });
  };
  back = (data) => {
    let selectArtistJson = [];
    let selectArtistName = [];
    for (let i = 0; i < data.length; i++) {
      selectArtistJson.push(data[i]);
      selectArtistName.length >= 3
        ? selectArtistName.push("...")
        : selectArtistName.push(data[i].name);
    }
    this.setState({
      onSelect: data,
      isRegisterPage: true,
      artis: selectArtistName,
      artisSelect: selectArtistJson,
    });
  };
  handleBlue = () => {
    this.setState({ bornPlacehoder: this.state.born === "" ? "วันเกิด" : "" });
  };
  handleUpload = () => {
    document.getElementById("btn-upload").click();
  };
  citySelect = async (event) => {
    Swal.fire({
      title: "กำลังค้นหาข้อมูล",
      text: "เขต/อำเภอ",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    await this.setState({ city: event, amphur: "", district: "", postNum: "" });
    this.props.form.setFieldsValue({
      amphur: undefined,
      district: undefined,
    });
    let setAmpurData = (data) => {
      this.setState({ amphurData: data });
      Swal.close();
    };
    await httpClient
      .post("frontend/api/address/amphures", {
        province_id: event,
      })
      .then((doc) => {
        setAmpurData(doc.data.data);
      })
      .catch((err) => {
        throw err;
      });
  };
  amphurSelect = async (event) => {
    Swal.fire({
      title: "กำลังค้นหาข้อมูล",
      text: "แขวง/ตำบล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    await this.setState({ amphur: event, district: "", postNum: "" });
    this.props.form.setFieldsValue({
      district: undefined,
    });
    let setDisData = (data) => {
      Swal.close();
      this.setState({ districtData: data });
    };
    await httpClient
      .post("frontend/api/address/districts", {
        amphure_id: event,
      })
      .then((doc) => {
        setDisData(doc.data.data);
      })
      .catch((err) => {
        throw err;
      });
  };
  districtSelect = async (event) => {
    let data = JSON.parse(event);
    this.setState({
      districtJson: event,
      district: data.id,
      postNum: data.zip_code,
    });
  };
  onSelectCredit = (e) => {
    this.setState({ checkbox: !this.state.checkbox });
    this.setState({ notiModal: this.state.checkbox === false ? true : false });
  };
  birthDaySelect = (e) => {
    let text = e.target.value;
    this.setState({
      born: moment(text).unix(),
      borntext: e.target.value,
      dataFalse: false,
    });
  };
  handleNewCode = async () => {
    Swal.fire({
      title: "กำลังส่ง OTP ไปยังมือถือ",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let setData = async (ref, otp) => {
      await this.setState({
        ref,
        otp,
        isCount: true,
        count: 59,
      });
      await Swal.close();
    };
    await httpClient
      .post("frontend/api/register/request/otp", {
        telephone: this.state.tel,
        ref: this.state.ref,
      })
      .then((doc) => {
        setData(doc.data.ref, doc.data.otp);
      })
      .catch((err) => {
        if (err.response.data.code === "1") {
          Swal.fire({
            text: err.response.data.message,
            type: "error",
          });
          this.setState({
            isCount: true,
            count: +err.response.data.counter,
          });
        } else {
          throw err;
        }
      });
  };
  renderers = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      this.timeOut();
      return <span>ส่งคำขอรหัสอีกครั้ง</span>;
    } else {
      return <span>กรุณารอ...{seconds} วินาที</span>;
    }
  };
  timeOut = () => {
    this.setState({
      isCount: false,
      count: 60,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { imageUrl, cityData, amphurData, districtData } = this.state;
    const cityOption = cityData.map((city) => (
      <Option key={city.id} value={city.id}>
        <Row type="flex" align="middle">
          <i className="material-icons">place</i>
          <span style={{ paddingLeft: 15 }}>{city.name_th}</span>
        </Row>
      </Option>
    ));
    const amphurOption = amphurData.map((amphur) => (
      <Option key={amphur.id} value={amphur.id}>
        <Row type="flex" align="middle">
          <i className="material-icons">account_balance</i>
          <span style={{ paddingLeft: 15 }}>{amphur.name_th}</span>
        </Row>
      </Option>
    ));
    const districtOption = districtData.map((district) => (
      <Option key={district.id} value={JSON.stringify(district)}>
        <Row type="flex" align="middle">
          <i className="material-icons">flag</i>
          <span style={{ paddingLeft: 15 }}>{district.name_th}</span>
        </Row>
      </Option>
    ));
    return (
      <Layout>
        {this.state.isRegisterPage === true &&
        this.state.visibleSubmit === false ? (
          <Form className="register-wrapper">
            {this.state.check === true && (
              <Card className="card-register" bordered={false}>
                <div className="avatar__user">
                  <Avatar className="avatar-user" size={150} src={imageUrl} />
                  <span className="edit-icon" onClick={this.handleUpload}>
                    <i className="material-icons">edit</i>
                    {getFieldDecorator("upload", {
                      valuePropName: "fileList",
                      getValueFromEvent: this.normFile,
                    })(
                      <Upload
                        fileList={this.state.imageFile}
                        name="image"
                        customRequest={dummyRequest}
                        listType="text"
                        beforeUpload={beforeUpload}
                        onChange={this.handleChangePhoto}
                      >
                        <button
                          id="btn-upload"
                          className="upload-button"
                        ></button>
                      </Upload>
                    )}
                  </span>
                </div>
                <div></div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: -15,
                    marginBottom: 25,
                  }}
                >
                  <span style={{ fontWeight: 400, fontSize: 14 }}>
                    กรอกชื่อและนามสกุลจริง เพื่อใช้ยืนยันการรับรางวัล
                  </span>
                </div>
                <Form.Item>
                  {getFieldDecorator("name", {
                    initialValue: this.state.name,
                    rules: [
                      { required: true, message: "กรุณากรอกชื่อ-นามสกุล" },
                    ],
                  })(
                    <Input
                      name="name"
                      className="input-normal"
                      prefix={<i className="material-icons">account_circle</i>}
                      suffix={
                        <i className="material-icons">{this.state.isName}</i>
                      }
                      minLength={3}
                      type="text"
                      placeholder="ชื่อ-นามสกุล*"
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  )}
                </Form.Item>
                <Form.Item className="gender-from">
                  {getFieldDecorator("gender", {
                    initialValue: this.state.gender,
                    rules: [{ required: true, message: "โปรดเลือกเพศของคุณ" }],
                  })(
                    <Select
                      className="input-city input-gender"
                      placeholder={
                        <Row type="flex" align="middle">
                          <div
                            style={{ marginTop: -10, marginLeft: -10 }}
                            className="gender-man"
                          ></div>
                          <span
                            style={{
                              paddingLeft: 10,
                              fontSize: 16,
                              color: "#bfbfbf",
                              marginTop: -10,
                              marginLeft: -10,
                            }}
                          >
                            เลือกเพศ*
                          </span>
                        </Row>
                      }
                      style={{ width: "100%" }}
                      onChange={(e) => this.setState({ gender: e })}
                    >
                      <Option value="1">
                        <Row type="flex" align="middle">
                          <div
                            style={{ marginLeft: -7, marginTop: -5 }}
                            className="gender-man"
                          ></div>
                          <span
                            style={{
                              paddingLeft: 10,
                              marginLeft: -7,
                              marginTop: -5,
                            }}
                          >
                            ชาย
                          </span>
                        </Row>
                      </Option>
                      <Option value="2">
                        <Row type="flex" align="middle">
                          <div
                            style={{ marginLeft: -7, marginTop: -5 }}
                            className="gender-women"
                          ></div>
                          <span
                            style={{
                              paddingLeft: 10,
                              marginLeft: -7,
                              marginTop: -5,
                            }}
                          >
                            หญิง
                          </span>
                        </Row>
                      </Option>
                      <Option value="3">
                        <Row type="flex" align="middle">
                          <div
                            style={{ marginLeft: -7, marginTop: -5 }}
                            className="gender-both"
                          ></div>
                          <span
                            style={{
                              paddingLeft: 10,
                              marginLeft: -7,
                              marginTop: -5,
                            }}
                          >
                            ไม่ระบุเพศ
                          </span>
                        </Row>
                      </Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item className="born__form">
                  <div className="date-icon"></div>
                  {getFieldDecorator("born", {
                    initialValue: this.state.borntext,
                    rules: [
                      {
                        required: true,
                        message: "วันเดือนปีเกิดของคุณไม่ถูกต้อง",
                      },
                    ],
                  })(
                    <Input
                      name="born"
                      className="input-normal-born w-100"
                      minLength={3}
                      type="date"
                      onClick={() => this.setState({ bornPlacehoder: "" })}
                      onBlur={this.handleBlue}
                      // placeholder={this.state.bornPlacehoder}
                      onChange={this.birthDaySelect}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("tel", {
                    initialValue: this.state.tel,
                    rules: [
                      {
                        max: 10,
                        min: 10,
                        message: "กรุณากรอกเบอร์โทรศัพท์ 10 ตัว",
                      },
                      {
                        pattern: "[0-9]",
                        message: "กรุณากรอกเฉพาะตัวเลขเท่านั้น",
                      },
                      {
                        required: true,
                        message: "หมายเลขโทรศัพท์ของคุณไม่ถูกต้อง",
                      },
                    ],
                  })(
                    <Input
                      name="tel"
                      className="input-normal"
                      prefix={<i className="material-icons">phone</i>}
                      suffix={
                        <i className="material-icons">{this.state.isTel}</i>
                      }
                      minLength={10}
                      maxLength={10}
                      type="number"
                      placeholder="เบอร์โทร*"
                      onChange={(e) => this.setState({ tel: e.target.value })}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("email", {
                    rules: [{ required: true, message: "กรุณากรอกอีเมล" }],
                    initialValue: this.state.email,
                  })(
                    <Input
                      name="email"
                      className="input-normal"
                      prefix={<i className="material-icons">drafts</i>}
                      minLength={3}
                      type="text"
                      placeholder="อีเมล*"
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("place", {
                    initialValue: this.state.place,
                  })(
                    <Input
                      name="place"
                      className="input-normal"
                      prefix={<i className="material-icons">alt_route</i>}
                      minLength={3}
                      type="text"
                      placeholder="ที่อยู่"
                      onChange={(e) => this.setState({ place: e.target.value })}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("city", {
                    initialValue: this.state.city,
                    rules: [
                      { required: true, message: "โปรดเลือกจังหวัดของคุณ" },
                    ],
                  })(
                    <Select
                      className="input-city"
                      placeholder={
                        <Row type="flex" align="middle">
                          <i
                            className="material-icons"
                            style={{ fontSize: 23 }}
                          >
                            place
                          </i>
                          <span
                            style={{
                              fontSize: 16,
                              paddingLeft: 15,
                              color: "#bfbfbf",
                            }}
                          >
                            จังหวัด*
                          </span>
                        </Row>
                      }
                      style={{ width: "100%" }}
                      onChange={this.citySelect}
                    >
                      {cityOption}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("amphur", {
                    initialValue: this.state.amphur || (
                      <Row type="flex" align="middle">
                        <i className="material-icons" style={{ fontSize: 22 }}>
                          account_balance
                        </i>
                        <span
                          style={{
                            fontSize: 16,
                            paddingLeft: 15,
                            color: "#bfbfbf",
                          }}
                        >
                          เขต/อำเภอ
                        </span>
                      </Row>
                    ),
                    // rules: [
                    //   { required: true, message: "โปรดเลือกเขต/อำเภอของคุณ" },
                    // ],
                  })(
                    <Select
                      className="input-city"
                      placeholder={
                        <Row type="flex" align="middle">
                          <i
                            className="material-icons"
                            style={{ fontSize: 22 }}
                          >
                            account_balance
                          </i>
                          <span
                            style={{
                              fontSize: 16,
                              paddingLeft: 15,
                              color: "#bfbfbf",
                            }}
                          >
                            เขต/อำเภอ
                          </span>
                        </Row>
                      }
                      style={{ width: "100%" }}
                      onChange={this.amphurSelect}
                    >
                      {amphurOption}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("district", {
                    initialValue: this.state.districtJson || (
                      <Row type="flex" align="middle">
                        <i className="material-icons" style={{ fontSize: 22 }}>
                          flag
                        </i>
                        <span
                          style={{
                            fontSize: 16,
                            paddingLeft: 15,
                            color: "#bfbfbf",
                          }}
                        >
                          แขวง/ตำบล
                        </span>
                      </Row>
                    ),
                    // rules: [
                    //     { required: true, message: "โปรดเลือกแขวง/ตำบลของคุณ" },
                    //   ],
                  })(
                    <Select
                      className="input-city"
                      placeholder={
                        <Row type="flex" align="middle">
                          <i
                            className="material-icons"
                            style={{ fontSize: 22 }}
                          >
                            flag
                          </i>
                          <span
                            style={{
                              fontSize: 16,
                              paddingLeft: 15,
                              color: "#bfbfbf",
                            }}
                          >
                            แขวง/ตำบล
                          </span>
                        </Row>
                      }
                      style={{ width: "100%" }}
                      onChange={this.districtSelect}
                    >
                      {districtOption}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("postNum", {
                    initialValue: this.state.postNum,
                  })(
                    <Input
                      className="input-normal"
                      prefix={
                        <i className="material-icons">local_post_office</i>
                      }
                      disabled
                      placeholder="รหัสไปรษณีย์"
                      onChange={(e) =>
                        this.setState({ postNum: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
                <div
                  onClick={() => {
                    this.state.artisData.length > 0 &&
                      this.setState({ isRegisterPage: false });
                  }}
                >
                  <Form.Item>
                    {getFieldDecorator("artis", {
                      initialValue: this.state.artis,
                      rules: [
                        {
                          required: true,
                          message: "กรุณาเลือกศิลปินที่ชื่นชอบ",
                        },
                      ],
                    })(
                      <Input
                        name="artis"
                        className="input-normal"
                        prefix={<i className="material-icons">library_music</i>}
                        suffix={
                          <i className="material-icons">{this.state.isArtis}</i>
                        }
                        minLength={3}
                        disabled
                        type="text"
                        placeholder="ศิลปินที่ชื่นชอบ*"
                      />
                    )}
                  </Form.Item>
                </div>
                <Form.Item
                  style={{ textAlign: "center", marginTop: "30px" }}
                  className="m-0"
                >
                  {getFieldDecorator("credit", {
                    valuePropName: "credit",
                  })(
                    <Checkbox
                      checked={this.state.checkbox}
                      onChange={this.onSelectCredit}
                    >
                      <span
                        to="#"
                        style={{ color: "#6B6B6B" }}
                        onClick={() => {
                          this.setState({
                            notiModal:
                              this.state.checkbox === false ? true : false,
                          });
                        }}
                      >
                        ยอมรับเงื่อนไขและข้อตกลง
                      </span>
                    </Checkbox>
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    marginRight="auto"
                    marginLeft="auto"
                    width="100%"
                    onClick={this.handleSubmit}
                    block
                  >
                    บันทึก
                  </Button>
                </Form.Item>
              </Card>
            )}
            <Modal
              className="register-modal"
              style={{ textAlign: "center" }}
              maskClosable={false}
              centered
              visible={this.state.visible}
              onOk={this.handleOk}
              cancelButtonProps={{ style: { display: "none" } }}
              footer={[]}
            >
              <h3 className="text-center">{this.state.text}</h3>
              <div
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 15,
                }}
              >
                <Button
                  marginLeft="30px"
                  block
                  width="80%"
                  onClick={() => this.handleOk()}
                >
                  {"สมัครสมาชิก"}
                </Button>
              </div>
            </Modal>
            <Modal
              maskClosable={false}
              className="sendcode-modal"
              centered
              visible={this.state.visibleSubmit}
              footer={[
                <Row className="mt-1">
                  <Button
                    marginLeft="40px"
                    width="80%"
                    onClick={this.success}
                    key="ok"
                    block
                  >
                    ยืนยัน
                  </Button>
                </Row>,
                <Row className="mt-1">
                  <Button
                    disabled={this.state.isCount === true ? true : false}
                    border="2px solid"
                    borderColor="#01A69D"
                    marginLeft="40px"
                    color="#01A69D"
                    bgColor="transparent"
                    width="80%"
                    key="again"
                    onClick={this.handleNewCode}
                    block
                  >
                    {this.state.isCount === true ? (
                      <Countdown
                        date={Date.now() + this.state.count * 1000}
                        renderer={this.renderers}
                      />
                    ) : (
                      "ส่งคำขอรหัสอีกครั้ง"
                    )}
                  </Button>
                </Row>,
                <Row className="mt-1">
                  <Button
                    marginLeft="40px"
                    color="#FFFFFF"
                    bgColor="#FE0000"
                    width="80%"
                    onClick={() => this.setState({ visibleSubmit: false })}
                    key="cancel"
                    block
                  >
                    ยกเลิก
                  </Button>
                </Row>,
              ]}
            >
              <h1 className="text-center">
                <b>รหัส OTP สำหรับการยืนยัน</b>
              </h1>
              <h3 className="text-center">ทางเราได้ทำการส่งรหัส</h3>
              <h3 className="text-center">ไปยังโทรศัพท์ของคุณ</h3>
              <h3 className="text-center" style={{ margin: 20 }}>
                *รหัสมีอายุ 3 นาที*
              </h3>
              <h3 className="text-center">หากไม่ได้รับข้อความ</h3>
              <h3 className="text-center">ให้กดส่งคำขอรหัสอีกครั้ง</h3>
              <Row type="flex" justify="center" align="middle">
                <Col span={12}>
                  <h3 className="text-dark">กรอกรหัส 6 หลัก</h3>
                </Col>
                <Col span={12}>
                  <Input
                    onChange={(e) => this.setState({ otp: e.target.value })}
                    className="input-phonecode"
                    name="code"
                    minLength={3}
                    // value={this.state.otp}
                    type="number"
                    placeholder="OTP"
                  />
                </Col>
              </Row>
              <Row className="mt-1" type="flex" justify="center" align="middle">
                <Col span={12}>
                  <h3 className="text-dark">Ref :</h3>
                </Col>
                <Col span={12}>
                  <h3 className="text-dark">{this.state.ref}</h3>
                </Col>
              </Row>
            </Modal>
            <Modal
              className="sendcode-modal"
              style={{ height: 600 }}
              centered
              visible={this.state.notiModal}
              onCancel={this.handleCancel}
              footer={[
                <Button
                  marginLeft="40px"
                  width="80%"
                  onClick={() => this.setState({ notiModal: false })}
                  key="ok"
                  block
                >
                  ยืนยัน
                </Button>,
              ]}
            >
              <h2 className="text-center">
                <b>เงื่อนไขและข้อตกลง</b>
              </h2>
              <p>
                บริษัท สไปรซ์ซี่ ดิสก์ จำกัด (Spicydisc)
                ได้ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคล
                &nbsp;โดยขอแจ้งประกาศนโยบายคุ้มครองข้อมูลส่วนบุคคล (PDPA)
                ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                เพื่อสมาชิกSpicyClub ดังนี้
              </p>
              <ul>
                <li>
                  ข้อมูลส่วนบุคคล หมายถึง ชื่อ สกุล ที่อยู่ของสมาชิก
                  เบอร์โทรศัพท์ อีเมล์หรือข้อมูลใดๆ
                  ที่จะเป็นประโยชน์ในการให้บริการสมาชิก
                  ซึ่งเกี่ยวกับสมาชิกที่สามารถระบุตัวตนได้ไม่ว่าทางตรง
                  หรือทางอ้อมที่สมาชิกให้กับ Spicydisc หรือที่Spicydisc
                  ได้รับหรือเข้าถึงได้จากแหล่งอื่น
                </li>
                <li>
                  Spicydisc รวบรวม จัดเก็บ ใช้ ข้อมูลส่วนบุคคลของสมาชิก
                  รวมถึงบริการ เช่น การวิจัยตลาดและการจัดกิจกรรมส่งเสริมการขาย
                  หรือเพื่อประโยชน์ในการจัดทำฐานข้อมูลและใช้ข้อมูลเพื่อเสนอสิทธิ
                  ประโยชน์ตามความสนใจของสมาชิก
                  หรือเพื่อประโยชน์ในการวิเคราะห์และนำเสนอบริการหรือผลิตภัณฑ์ใดๆ
                  ของ Spicydisc &nbsp;และบริษัทในเครือ &nbsp;โดยSpicydisc โดย
                  Spicydisc
                  จะจัดเก็บรักษาข้อมูลดังกล่าวไว้ตามระยะเวลาเท่าที่จำเป็นสำหรับวัตถุประสงค์เหล่านั้นเท่านั้น
                  โดยผู้รับข้อมูลก็มีหน้าที่ตามกฎหมายในการรักษาข้อมูลส่วนบุคคลเช่นเดียวกัน
                  หากภายหลังมีการเปลี่ยนแปลง
                  วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
                  Spicydiscจะประกาศให้สมาชิกทราบ &nbsp;
                </li>
                <li>
                  สมาชิกมีสิทธิถอนความยินยอมเกี่ยวกับข้อมูลส่วนบุคคลของผู้ใช้บริการเมื่อใดก็ได้
                  เว้นแต่การถอนความยินยอม
                  นั้นจะกระทบต่อการให้บริการหรืออยู่นอกเหนือการควบคุมของSpicydisc&nbsp;
                </li>
                <li>
                  การตกลงให้เก็บ รวบรวม ใช้
                  เปิดเผยข้อมูลส่วนบุคคลนี้มีผลใช้บังคับตามระยะเวลาที่กฎหมายกำหนดไว้
                </li>
                <li>
                  สิทธิของสมาชิกกี่ยวกับข้อมูลส่วนบุคคลที่เกี่ยวกับการเพิกถอน
                  การขอเข้าถึงและรับสำเนาข้อมูลส่วนบุคคล การ
                  คัดค้านการเก็บข้อมูล ใช้หรือเปิดเผย
                  สิทธิในการเคลื่อนย้ายข้อมูล สิทธิในการลบข้อมูล
                  สิทธิในการระงับการใช้ข้อมูลให้
                  เป็นไปตามประกาศการคุ้มครองข้อมูลส่วนบุคคลของเราและตามที่กฎหมายกำหนด
                  &nbsp;
                </li>
              </ul>
              <p>
                หากท่านต้องการสอบถามหรือมีความประสงค์ที่จะใช้สิทธิของท่านที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่าน
                โปรดติดต่อมายัง email:&nbsp;spicyclub@spicydisc.com &nbsp;
              </p>
            </Modal>
            <Modal
              maskClosable={false}
              className="sendcode-modal"
              centered
              visible={this.state.loginModal}
              footer={[
                <Row className="mt-1">
                  <Button
                    marginLeft="40px"
                    width="80%"
                    onClick={() => liff.login()}
                    key="ok"
                    block
                  >
                    ยืนยัน
                  </Button>
                </Row>,
                <Row className="mt-1">
                  <Button
                    border="2px solid"
                    borderColor="#01A69D"
                    marginLeft="40px"
                    color="#01A69D"
                    bgColor="transparent"
                    width="80%"
                    key="again"
                    onClick={() => this.setState({ loginModal: false })}
                    block
                  >
                    ยกเลิก
                  </Button>
                </Row>,
              ]}
            >
              <h1 className="text-center">
                <b>Login ด้วย Line</b>
              </h1>
            </Modal>
          </Form>
        ) : this.state.isRegisterPage === true &&
          this.state.visibleSubmit === true ? (
          <div>
            <div style={{ padding: "30px 50px 20px 50px" }}>
              <h1 className="text-center">
                <b>รหัส OTP สำหรับการยืนยัน</b>
              </h1>
              <h3 className="text-center">ทางเราได้ทำการส่งรหัส</h3>
              <h3 className="text-center">ไปยังโทรศัพท์ของคุณ</h3>
              <h3 className="text-center" style={{ margin: 20 }}>
                *รหัสมีอายุ 3 นาที*
              </h3>
              <h3 className="text-center">หากไม่ได้รับข้อความ</h3>
              <h3 className="text-center">ให้กดส่งคำขอรหัสอีกครั้ง</h3>
              <Row type="flex" justify="center" align="middle">
                <Col span={12}>
                  <h3 className="text-dark">กรอกรหัส 6 หลัก</h3>
                </Col>
                <Col span={12}>
                  <Input
                    onChange={(e) => this.setState({ otp: e.target.value })}
                    className="input-phonecode"
                    name="code"
                    minLength={3}
                    // value={this.state.otp}
                    type="number"
                    placeholder="OTP"
                  />
                </Col>
              </Row>
              <Row className="mt-1" type="flex" justify="center" align="middle">
                <Col span={12}>
                  <h3 className="text-dark">Ref :</h3>
                </Col>
                <Col span={12}>
                  <h3 className="text-dark">{this.state.ref}</h3>
                </Col>
              </Row>
            </div>
            <Row className="mt-1">
              <Button
                marginLeft="40px"
                width="80%"
                onClick={this.success}
                key="ok"
                block
              >
                ยืนยัน
              </Button>
            </Row>
            <Row className="mt-1">
              <Button
                disabled={this.state.isCount === true ? true : false}
                border="2px solid"
                borderColor="#01A69D"
                marginLeft="40px"
                color="#01A69D"
                bgColor="transparent"
                width="80%"
                key="again"
                onClick={this.handleNewCode}
                block
              >
                {this.state.isCount === true ? (
                  <Countdown
                    date={Date.now() + this.state.count * 1000}
                    renderer={this.renderers}
                  />
                ) : (
                  "ส่งคำขอรหัสอีกครั้ง"
                )}
              </Button>
            </Row>
            <Row className="mt-1">
              <Button
                marginLeft="40px"
                color="#FFFFFF"
                bgColor="#FE0000"
                width="80%"
                onClick={() => this.setState({ visibleSubmit: false })}
                key="cancel"
                block
              >
                ยกเลิก
              </Button>
            </Row>
          </div>
        ) : (
          <FavArtist
            onSelect={this.state.onSelect}
            back={this.back}
            artistData={this.state.artisData}
          />
        )}
      </Layout>
    );
  }
}

const RegisterComponent = Form.create({ name: "register" })(registerComponent);
function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
    isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);
