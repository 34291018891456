import React, { Component } from 'react'
import Layout from "../../commons/Layout/Layout";
import Button from '../../commons/buttonComponent/buttonComponent'
import { 
    Modal,
    Row,
    Col,
    Tabs
} from 'antd'
import QRCode from 'qrcode.react'
import './styles.css'
import ReactDOM from 'react-dom'
/* redux */
import * as ACTION from "../../../store/actions/action";
import { connect } from "react-redux";
/* redux */
import { httpClient } from "../../../utils/HttpClient";
import Swal from 'sweetalert2';
var setTimeOut;

const { TabPane } = Tabs

class usePrivilegeComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            usePoint: false,
            start_date : '',
            end_date : '',
            detail: '',
            img: '/images/products/image-default.png',
            id:0,
            name:'',
            points:0,
            expired:0,
            code:''
        }
    }
    componentDidMount () {
      this.props.isLoadingActive()
      let back = (data) => {
        this.props.isPoint(data.data.customers_points.points)
      }
      httpClient.get('frontend/api/points/get').then(doc=>{
              back(doc.data);
      }).catch(err=>{
          throw err
      })
      this.fetchData()
   }
   fetchData = async()=>{
     const {id} = this.props.location.state
     let setBannerData = async(doc,voucher_status,voucher_code) => {
      await this.setState({
         start_date : doc.events_details.start_date,
         end_date : doc.events_details.end_date,
         detail: doc.events_details.detail,
         amount: doc.events_details.amount,
         amount_used: doc.events_details.amount_used,
         img: doc.image_m,
         id:doc.id,
         name:doc.name,
         points:doc.points,
         voucher_status,
         code:voucher_status === 3 ? voucher_code.code : '',
         expired : voucher_status === 3 ? voucher_code.expired_seconds < 60 ? 1 : voucher_code.expired_seconds / 60 :0,
         usePoint:voucher_status === 3 ? true : false
       })
       voucher_status === 3 && await this.handleOk()
       await this.props.isLoadingDeActive()
     };
   httpClient
   .get(`frontend/api/voucher_c/view/${id}`)
   .then((doc) => {
       setBannerData(doc.data.data,doc.data.voucher_status,doc.data.voucher_code)
   })
   .catch((err) => {
       throw err;
   });
   }
    timeCountSecound =()=>{
        const node = ReactDOM.findDOMNode(this)
        const FULL_DASH_ARRAY = 283;
        const WARNING_THRESHOLD = 10;
        const ALERT_THRESHOLD = 5;

        const COLOR_CODES = {
        info: {
            color: "green"
        },
        warning: {
            color: "orange",
            threshold: WARNING_THRESHOLD
        },
        alert: {
            color: "red",
            threshold: ALERT_THRESHOLD
        }
        };

        const TIME_LIMIT = 60;
        let timePassed = 0;
        let timeLeft = TIME_LIMIT;
        let timerInterval = null;
        let remainingPathColor = COLOR_CODES.info.color;

        node.querySelector('#sec').innerHTML = `
        <div class="base-timer">
        <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
            <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
            <path
                id="base-timer-path-remaining"
                stroke-dasharray="283"
                class="base-timer__path-remaining ${remainingPathColor}"
                d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
                "
            ></path>
            </g>
        </svg>
        <span id="base-timer-label" class="base-timer__label">${formatTime(
            timeLeft
        )}</span>
        <span class="base-timer__min">วินาที</span>
        </div>
        `;
        let refresh = () => this.timeCountSecound()
        startTimer();
        function onTimesUp() {
            clearInterval(timerInterval);
            refresh()
          }
          
          function startTimer() {
            timerInterval = setInterval(() => {
              timePassed = timePassed += 1;
              timeLeft = TIME_LIMIT - timePassed;
              node.querySelector("#base-timer-label").innerHTML = formatTime(
                timeLeft
              );
              setCircleDasharray();
              setRemainingPathColor(timeLeft);
              if (timeLeft === 0) {
                onTimesUp();
              }
            }, 1000);
          }
          
          function formatTime(time) {
            // const minutes = Math.floor(time / 60);
            let seconds = time % 60;
          //   if (seconds === 0) {
          //     seconds = `6${seconds}`;
          // }
            if (seconds < 10) {
              seconds = `0${seconds}`;
            }
          
            return `${seconds}`;
          }
          
          function setRemainingPathColor(timeLeft) {
            const { alert, warning, info } = COLOR_CODES;
            if (timeLeft <= alert.threshold) {
              node.querySelector("#base-timer-path-remaining")
                .classList.remove(warning.color);
                node.querySelector("#base-timer-path-remaining")
                .classList.add(alert.color);
            } else if (timeLeft <= warning.threshold) {
              node.querySelector("#base-timer-path-remaining")
                .classList.remove(info.color);
                node.querySelector("#base-timer-path-remaining")
                .classList.add(warning.color);
            }
          }
          
          function calculateTimeFraction() {
            const rawTimeFraction = timeLeft / TIME_LIMIT;
            return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
          }
          
          function setCircleDasharray() {
            const circleDasharray = `${(
              calculateTimeFraction() * FULL_DASH_ARRAY
            ).toFixed(0)} 283`;
            node.querySelector("#base-timer-path-remaining")
              .setAttribute("stroke-dasharray", circleDasharray);
          } 
    }
    timeCountMin =()=>{
      const node = ReactDOM.findDOMNode(this)
      let {expired} = this.state
      var min = expired.toFixed(0) - 1
        const FULL_DASH_ARRAY = 283;
        const WARNING_THRESHOLD = 10;
        const ALERT_THRESHOLD = 5;

        const COLOR_CODES = {
        info: {
            color: "green"
        },
        warning: {
            color: "orange",
            threshold: WARNING_THRESHOLD
        },
        alert: {
            color: "red",
            threshold: ALERT_THRESHOLD
        }
        };

        const TIME_LIMIT = 59;
        let timePassed = 0;
        let timeLeft = TIME_LIMIT;
        let timerInterval = null;
        let remainingPathColor = COLOR_CODES.info.color;

        node.querySelector("#min").innerHTML = `
        <div class="base-timer2">
        <svg class="base-timer__svg2" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle2">
            <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
            <path
                id="base-timer-path-remaining2"
                stroke-dasharray="283"
                class="base-timer__path-remaining2 ${remainingPathColor}"
                d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
                "
            ></path>
            </g>
        </svg>
        <span id="base-timer-label2" class="base-timer__label2">${formatTime(
            timeLeft
        )}</span>
        <span class="base-timer__sec">นาที</span>
        </div>
        `;
        startTimer();
        async function onTimesUp() {
            await clearInterval(timerInterval);
          }
          
          function startTimer() {
            timerInterval = setInterval(() => {
              min = min - 1
              timePassed = timePassed += 1;
              timeLeft = TIME_LIMIT - timePassed;
              node.querySelector("#base-timer-label2").innerHTML = formatTime(
                timeLeft
              );
              setCircleDasharray();
              setRemainingPathColor(timeLeft);
              if (timeLeft === 0) {
                onTimesUp();
              }
            }, 60000);
          }
          
          function formatTime(time) {
            // const minutes = Math.floor(time / 60);
            if (min === 0) {
              setTimeout(() => {
                Swal.fire({  
                  title: 'หมดเวลา!',  
                  type: 'warning',  
                  text: 'ระบบกำลังเปลี่ยนหน้า',
                  timer: 1500,
                  })
                setTimeout(() => {
                    window.location.href = '/profile'
                }, 1500)
              }, 60000)
            }
            return `${min}`;
          }
          
          function setRemainingPathColor(timeLeft) {
            const { alert, warning, info } = COLOR_CODES;
            if (timeLeft <= alert.threshold) {
              node.querySelector("#base-timer-path-remaining2")
                .classList.remove(warning.color);
                node.querySelector("#base-timer-path-remaining2")
                .classList.add(alert.color);
            } else if (timeLeft <= warning.threshold) {
              node.querySelector("#base-timer-path-remaining2")
                .classList.remove(info.color);
                node.querySelector("#base-timer-path-remaining2")
                .classList.add(warning.color);
            }
          }
          
          function calculateTimeFraction() {
            const rawTimeFraction = timeLeft / TIME_LIMIT;
            return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
          }
          
          function setCircleDasharray() {
            const circleDasharray = `${(
              calculateTimeFraction() * FULL_DASH_ARRAY
            ).toFixed(0)} 283`;
            node.querySelector("#base-timer-path-remaining2")
              .setAttribute("stroke-dasharray", circleDasharray);
          } 
    }
    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    handleOk = e => {
    this.setState({
        visible: false,
        visibleSubmit:false,
        usePoint:true
    });
    this.timeCountSecound()
    this.timeCountMin()
    };
    handleCancel = e => {
    this.setState({
        visible: false,
        visibleSubmit:false
    });
    };
    handleSubmit = e => {
        this.setState({
            visibleSubmit: true,
        });
    }
    wait = (expired) =>{
      let time = +expired
      setTimeOut = setTimeout(() => {
        Swal.fire({  
          title: 'หมดเวลา!',  
          type: 'warning',  
          text: 'ระบบกำลังเปลี่ยนหน้า',
          timer: 1500,
          })
        setTimeout(() => {
            window.location.href = '/profile'
        }, 1500)
      }, time * 60000)
    }
    useCode = async() =>{
      Swal.fire({
        title: "กำลังตรวจสอบ",
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });
      await this.setState({
        visible:false})
      let setData = async(data) => {
        await Swal.close()
        await this.setState({
            code:data.code,
            expired : data.expired_seconds < 60 ? 1 : data.expired_seconds / 60,
            usePoint:true
          })
        let expired = data.expired_seconds < 60 ? 1 : data.expired_seconds / 60
        let sec =  this.timeCountSecound()
        let min = this.timeCountMin()
        let wait = this.wait(expired)
        await Promise.all([wait,sec,min])
      }
      await httpClient.post('frontend/api/voucher_c/use',
          {
              id : this.state.id
          }).then(doc=>{
              setTimeout(() => {
                  setData(doc.data);
              }, 2000)
      }).catch(err=>{
          throw err
      })
    }
    cancelCode = () =>{
      Swal.fire({
        title: 'คุณต้องการปิด หากปิดแล้วไม่สามารถกลับมาใช้ได้อีก',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#01A69D',
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          clearTimeout(setTimeOut);
          Swal.fire({
            title: "กำลังปิด",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            }
          });
          let setData = () => {
            window.location.href = '/profile'
          }
          httpClient.post('frontend/api/voucher_c/cancel',
              {
                  id : this.state.id
              }).then(doc=>{
                  Swal.fire({  
                    title: 'ใช้สิทธิ์เรียบร้อยแล้ว!',  
                    type: 'success',  
                    text: 'ระบบกำลังเปลี่ยนหน้า',
                    timer: 3000,
                    })
                  setTimeout(() => {
                      setData();
                  }, 3500)
          }).catch(err=>{
              throw err
          })
        }
      })
    }
    render() {
      let { name,start_date,end_date,img,detail,points } = this.state
        return (
            <div className='point-wrapper'>
                <Layout>
                    <div>
                        <div className="point-title">
                            <span>ใช้ Point รับรางวัล</span>
                        </div>
                        <div className="product-img">
                        <img className="img-center" src={img} alt="logo" />
                        </div>
                        <div className="product-text">
                            <h1>{name}</h1>
                            <h3>{points} Point</h3>
                            <h4>
                                ขั้นตอนการแลก
                            </h4>
                            <h4>
                              กดใช้สิทธิ์เพื่อแลกรางวัล กดตกลงเพื่อยืนยันการใช้สิทธิ์ แสดงรหัสหรือ Qr Code ให้ทางร้านค้า
                            </h4>
                            <h4>
                              {detail && <div dangerouslySetInnerHTML={{ __html: detail }} />}
                            </h4>
                            <h4>
                                {start_date === end_date ? `แลกได้ถึง ${start_date} หรือจนกว่าสิทธิ์จะเต็มจำนวน` :`แลกได้ตั้งแต่ ${start_date} - ${end_date} หรือจนกว่าสิทธิ์จะเต็มจำนวน`}
                            </h4>
                            
                        </div>
                        {this.state.usePoint === true?
                        <div className="reward-tab-warpper" style={{background: '#F4F4F4'}}>
                          <Tabs type="card" defaultActiveKey="1" onChange={this.callback}>
                            <TabPane className='reward-tab' tab="Code" key="1">
                              <div className="random-code mt-2">
                                  <h4>กรุณาแสดงรหัสหน้าร้านเพื่อทำการรับสิทธิ์</h4>
                                  <h1>{this.state.code}</h1>
                              </div>
                            </TabPane>
                            <TabPane className='reward-tab' tab="Qr Code" key="2">
                              <div className="random-code mt-2">
                                <Row>
                                  <h4>กรุณาแสดง Qr Code หน้าร้านเพื่อทำการรับสิทธิ์</h4>
                                </Row>  
                                <Row type='flex' align='middle' justify='center'>
                                  <QRCode
                                      className='mt-2'
                                      value={this.state.code} // string
                                      id="printme"
                                      bgColor="#FFFFFF"
                                      size={256} // number
                                      level={"L"} // string ('L' 'M' 'Q' 'H')
                                      includeMargin={true} // boolean if true has bg white(default)
                                  />
                                </Row>  
                              </div>
                            </TabPane>
                          </Tabs>
                        </div>
                        :''}
                        <div className="countdown">
                            <Row className='min-row' type='flex' justify='center' align='middle'>
                                <Col xs={12}>
                                    <div id="min"></div>
                                </Col>
                                <Col xs={12}>
                                    <div id="sec"></div>
                                </Col>
                            </Row>
                        </div>
                        {this.state.usePoint === true?<div style={{margin:30}} className="random-code">
                            <h4>คุณสามารถใช้สิทธิ์ได้ภายใน {this.state.expired.toFixed(0)} นาที</h4>
                        </div>:''}
                        {this.state.usePoint === false ?<div className="product-button">
                            <Button marginTop='15px' marginBottom='15px' marginRight='auto' marginLeft='auto' onClick={()=>this.setState({visible:true})} fixed={false} block>
                                ใช้สิทธิ์
                            </Button>
                        </div>:''}
                        {this.state.usePoint === true?<div className="product-close-button">
                            <Button background='#f4f4f4' padding='15px 30px 15px 30px' width='100%' marginTop='0' marginBottom='0'
                             onClick={this.cancelCode} fixed={false} block>
                                ปิด
                            </Button>
                        </div> : ''}
                    </div>
                    <div>
                    <Modal
                        className="point-modal"
                        maskClosable={false}
                        centered
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            <div key='ok' className="btn-modal">
                                <Button marginLeft='auto' marginRight='auto' block onClick={this.useCode}>
                                    ตกลง
                                </Button>
                                <Button color="#01A69D" bgColor="transparent" marginLeft='auto' marginRight='auto' block onClick={this.handleCancel}>
                                    ปิด
                                </Button>
                            </div>
                        ]}
                        >
                            <h1 className='text-center'>ยืนยันการใช้สิทธิ์</h1>
                            <h3 className='text-center'>ใช้ สิทธิ์ {name} ฟรี!! กรุณาใช้รหัสภายในระยะเวลาที่กำหนด </h3>
                        </Modal>
                    </div>
                </Layout>
            </div>
        )
    }
}
function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading
  };
}
function mapDispatchToProps(dispatch) {
return {
  isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
  isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
  isPoint: (point) => dispatch(ACTION.isPoint(point))
};
}
export default connect(
mapStateToProps,
mapDispatchToProps
)(usePrivilegeComponent);