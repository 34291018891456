import React, { Component } from "react";
import Layout from "../../commons/Layout/Layout";
// import { Button } from "antd";
import Button from '../../commons/buttonComponent/buttonComponent'
import '../../../stylesheet/main.css'
import '../styles.css'
class registerSuccessComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <Layout>
        <div className='container'>
          <div className='text-center'>
            <img className="img-gift" src="/Success.png" alt="success" />
            <h1 className='font-title'>ขอต้อนรับสู่สมาชิก SPICY CLUB</h1>
            <h4>พบกับส่วนลดของรางวัลและสิทธิพิเศษมากมายง่ายๆเพียงร่วมสนุกสะสมพอยท์กับเราทุกวัน</h4>
          </div>
          <div>
            <Button marginLeft='15px' marginTop='30px' marginBottom='30px' onClick={()=>this.props.history.push('/profile')} left="15px" bottom="15px" block>
              แลกของรางวัล
            </Button>
          </div>
        </div>
      </Layout>
    );
  }
}
export default registerSuccessComponent;
