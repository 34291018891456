import React, { Component } from 'react'
import Layout from "../../commons/Layout/Layout";
import Button from '../../commons/buttonComponent/buttonComponent'
import { 
    Modal,
    Row,
    Col,
    Tabs
} from 'antd'
import QRCode from 'qrcode.react'
import ReactDOM from 'react-dom'
import './styles.css'

const { TabPane } = Tabs
class usePrivilegeNowComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            usePoint: false
        }
    }
    timeCountSecound =()=>{
      const node = ReactDOM.findDOMNode(this)
      const FULL_DASH_ARRAY = 283;
      const WARNING_THRESHOLD = 10;
      const ALERT_THRESHOLD = 5;

      const COLOR_CODES = {
      info: {
          color: "green"
      },
      warning: {
          color: "orange",
          threshold: WARNING_THRESHOLD
      },
      alert: {
          color: "red",
          threshold: ALERT_THRESHOLD
      }
      };

      const TIME_LIMIT = 60;
      let timePassed = 0;
      let timeLeft = TIME_LIMIT;
      let timerInterval = null;
      let remainingPathColor = COLOR_CODES.info.color;

      node.querySelector('#sec').innerHTML = `
      <div class="base-timer">
      <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <g class="base-timer__circle">
          <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
          <path
              id="base-timer-path-remaining"
              stroke-dasharray="283"
              class="base-timer__path-remaining ${remainingPathColor}"
              d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
              "
          ></path>
          </g>
      </svg>
      <span id="base-timer-label" class="base-timer__label">${formatTime(
          timeLeft
      )}</span>
      <span class="base-timer__min">วินาที</span>
      </div>
      `;
      let refresh = () => this.timeCountSecound()
      startTimer();
      function onTimesUp() {
          clearInterval(timerInterval);
          refresh()
        }
        
        function startTimer() {
          timerInterval = setInterval(() => {
            timePassed = timePassed += 1;
            timeLeft = TIME_LIMIT - timePassed;
            node.querySelector("#base-timer-label").innerHTML = formatTime(
              timeLeft
            );
            setCircleDasharray();
            setRemainingPathColor(timeLeft);
            if (timeLeft === 0) {
              onTimesUp();
            }
          }, 1000);
        }
        
        function formatTime(time) {
          // const minutes = Math.floor(time / 60);
          let seconds = time % 60;
          if (seconds === 0) {
            seconds = `6${seconds}`;
        }
          if (seconds < 10) {
            seconds = `0${seconds}`;
          }
        
          return `${seconds}`;
        }
        
        function setRemainingPathColor(timeLeft) {
          const { alert, warning, info } = COLOR_CODES;
          if (timeLeft <= alert.threshold) {
            node.querySelector("#base-timer-path-remaining")
              .classList.remove(warning.color);
              node.querySelector("#base-timer-path-remaining")
              .classList.add(alert.color);
          } else if (timeLeft <= warning.threshold) {
            node.querySelector("#base-timer-path-remaining")
              .classList.remove(info.color);
              node.querySelector("#base-timer-path-remaining")
              .classList.add(warning.color);
          }
        }
        
        function calculateTimeFraction() {
          const rawTimeFraction = timeLeft / TIME_LIMIT;
          return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
        }
        
        function setCircleDasharray() {
          const circleDasharray = `${(
            calculateTimeFraction() * FULL_DASH_ARRAY
          ).toFixed(0)} 283`;
          node.querySelector("#base-timer-path-remaining")
            .setAttribute("stroke-dasharray", circleDasharray);
        } 
  }
  timeCountMin =()=>{
    const node = ReactDOM.findDOMNode(this)
      const FULL_DASH_ARRAY = 283;
      const WARNING_THRESHOLD = 10;
      const ALERT_THRESHOLD = 5;

      const COLOR_CODES = {
      info: {
          color: "green"
      },
      warning: {
          color: "orange",
          threshold: WARNING_THRESHOLD
      },
      alert: {
          color: "red",
          threshold: ALERT_THRESHOLD
      }
      };

      const TIME_LIMIT = 59;
      let timePassed = 0;
      let timeLeft = TIME_LIMIT;
      let timerInterval = null;
      let remainingPathColor = COLOR_CODES.info.color;

      node.querySelector("#min").innerHTML = `
      <div class="base-timer2">
      <svg class="base-timer__svg2" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <g class="base-timer__circle2">
          <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
          <path
              id="base-timer-path-remaining2"
              stroke-dasharray="283"
              class="base-timer__path-remaining2 ${remainingPathColor}"
              d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
              "
          ></path>
          </g>
      </svg>
      <span id="base-timer-label2" class="base-timer__label2">${formatTime(
          timeLeft
      )}</span>
      <span class="base-timer__sec">นาที</span>
      </div>
      `;
      startTimer();
      async function onTimesUp() {
          await clearInterval(timerInterval);
        }
        
        function startTimer() {
          timerInterval = setInterval(() => {
            timePassed = timePassed += 1;
            timeLeft = TIME_LIMIT - timePassed;
            node.querySelector("#base-timer-label2").innerHTML = formatTime(
              timeLeft
            );
            setCircleDasharray();
            setRemainingPathColor(timeLeft);
            if (timeLeft === 0) {
              onTimesUp();
            }
          }, 60000);
        }
        
        function formatTime(time) {
          // const minutes = Math.floor(time / 60);
          let seconds = time % 60;
          if (seconds === 0) {
              seconds = `6${seconds}`;
          }
          if (seconds < 10) {
            seconds = `${seconds}`;
          }
        
          return `${seconds}`;
        }
        
        function setRemainingPathColor(timeLeft) {
          const { alert, warning, info } = COLOR_CODES;
          if (timeLeft <= alert.threshold) {
            node.querySelector("#base-timer-path-remaining2")
              .classList.remove(warning.color);
              node.querySelector("#base-timer-path-remaining2")
              .classList.add(alert.color);
          } else if (timeLeft <= warning.threshold) {
            node.querySelector("#base-timer-path-remaining2")
              .classList.remove(info.color);
              node.querySelector("#base-timer-path-remaining2")
              .classList.add(warning.color);
          }
        }
        
        function calculateTimeFraction() {
          const rawTimeFraction = timeLeft / TIME_LIMIT;
          return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
        }
        
        function setCircleDasharray() {
          const circleDasharray = `${(
            calculateTimeFraction() * FULL_DASH_ARRAY
          ).toFixed(0)} 283`;
          node.querySelector("#base-timer-path-remaining2")
            .setAttribute("stroke-dasharray", circleDasharray);
        } 
  }
    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    handleOk = e => {
    this.setState({
        visible: false,
        visibleSubmit:false,
        usePoint:true
    });
    this.timeCountSecound()
    this.timeCountMin()
    };

    handleCancel = e => {
    this.setState({
        visible: false,
        visibleSubmit:false
    });
    };
    handleSubmit = e => {
        this.setState({
            visibleSubmit: true,
        });
    }
    render() {
        return (
            <div className='point-wrapper'>
                <Layout>
                    <div>
                        <div className="point-title">
                            <span>ใช้ Point รับรางวัล</span>
                        </div>
                        <div className="product-img">
                        <img className="img-center" src='https://cdn.discordapp.com/attachments/669798707675267102/686473226070786048/1566788033790-WEB.png' alt="logo" />
                        </div>
                        <div className="product-text">
                            <h1>รับ DQ จำนวน 2 ถ้วย</h1>
                            <h3>3000 Point</h3>
                            <h4>
                                ใช้ 3000 Point แลกรับ DQ จำนวน 2 ถ้วย ฟรี!!
                                (จำกัด 2 สิทธิ์ /เดือน /บัญชีผู้ใช้งาน /บัตรประชาชน)
                            </h4>
                            <h4>
                                ขั้นตอนการแลก
                            </h4>
                            <h4>
                                กดใช้คะแนนเพื่อแลกรางวัล กดตกลงเพื่อยืนยันการใช้สิทธิ์
                            </h4>
                            <h4>
                                หมดเขตใช้คูปอง 31 มีนาคม 2563 หรือจนกว่าสิทธิ์จะเต็มจำนวน
                            </h4>
                        </div>
                        {this.state.usePoint === true?
                        <div className="reward-tab-warpper" style={{background: '#F4F4F4'}}>
                          <Tabs type="card" defaultActiveKey="1" onChange={this.callback}>
                            <TabPane className='reward-tab' tab="Code" key="1">
                              <div className="random-code mt-2">
                                  <h4>กรุณาแสดงรหัสหน้าร้านเพื่อทำการรับสิทธิ์</h4>
                                  <h1>ABCDEF</h1>
                              </div>
                            </TabPane>
                            <TabPane className='reward-tab' tab="Qr Code" key="2">
                              <div className="random-code mt-2">
                                <Row>
                                  <h4>กรุณาแสดง Qr Code หน้าร้านเพื่อทำการรับสิทธิ์</h4>
                                </Row>  
                                <Row type='flex' align='middle' justify='center'>
                                  <QRCode
                                      className='mt-2'
                                      value={'QrStr'} // string
                                      id="printme"
                                      bgColor="#FFFFFF"
                                      size={256} // number
                                      level={"L"} // string ('L' 'M' 'Q' 'H')
                                      includeMargin={true} // boolean if true has bg white(default)
                                  />
                                </Row>  
                              </div>
                            </TabPane>
                          </Tabs>
                        </div>
                        :''}
                        <div className="countdown">
                            <Row className='min-row' type='flex' justify='center' align='middle'>
                                <Col xs={12}>
                                    <div id="min"></div>
                                </Col>
                                <Col xs={12}>
                                    <div id="sec"></div>
                                </Col>
                            </Row>
                        </div>
                        {this.state.usePoint === true?<div style={{margin:30}} className="random-code">
                            <h4>คุณสามารถใช้สิทธิ์ได้ภายใน 60 นาที</h4>
                        </div>:''}
                        {this.state.usePoint === false ?<div className="product-button">
                            <Button marginBottom='15px' marginRight='auto' marginLeft='auto' onClick={()=>this.setState({visible:true})} fixed={false} block>
                                ใช้ Point แลก
                            </Button>
                        </div>:''}
                        {this.state.usePoint === true?<div className="product-close-button">
                            <Button background='#f4f4f4' padding='15px 30px 15px 30px' width='100%' marginTop='0' marginBottom='0' onClick={()=>{
                            window.location.href = '/reward';
                          }} fixed={false} block>
                                ปิด
                            </Button>
                        </div> : ''}
                    </div>
                    <div>
                    <Modal
                        className="point-modal"
                        centered
                        maskClosable={false}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            <div key='ok' className="btn-modal">
                                <Button marginLeft='auto' marginRight='auto' block onClick={this.handleOk}>
                                    ตกลง
                                </Button>
                                <Button color="#01A69D" bgColor="transparent" marginLeft='auto' marginRight='auto' block onClick={this.handleCancel}>
                                    ปิด
                                </Button>
                            </div>
                        ]}
                        >
                            <h1 className='text-center'>ยืนยันแลก Point</h1>
                            <h3 className='text-center'>ใช้ 3000 Points แลกรับ DQ จำนวน 2 ถ้วย ฟรี!! ( ใช้สิทธิ์ทันที ) รหัสมีอายุ 60 นาทีหลังจากกดตกลง กรุณาใช้รหัสภายในระยะเวลาที่กำหนด </h3>
                        </Modal>
                    </div>
                </Layout>
            </div>
        )
    }
}
export default usePrivilegeNowComponent