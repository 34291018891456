import React, { Component } from 'react'
import Layout from "../../commons/Layout/Layout";
import Button from '../../commons/buttonComponent/buttonComponent'
import { 
    Modal} from 'antd'
import './styles.css'
/* redux */
import * as ACTION from "../../../store/actions/action";
import { connect } from "react-redux";
/* redux */
import { httpClient } from "../../../utils/HttpClient";
import Swal from 'sweetalert2';

class pointToPrivilegeComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            usePoint: false,
            start_date : '',
            end_date : '',
            detail: '',
            img: '/images/products/image-default.png',
            id:0,
            name:'',
            points:0
        }
    }
    componentDidMount () {
       this.props.isLoadingActive()
       let back = (data) => {
        this.props.isPoint(data.data.customers_points.points)
        }
        httpClient.get('frontend/api/points/get').then(doc=>{
                back(doc.data);
        }).catch(err=>{
            throw err
        })
       this.fetchData()
    }
    fetchData = async()=>{
      const {id} = this.props.location.state
      let setBannerData = (doc) => {
        this.setState({
          start_date : doc.events_details.start_date,
          end_date : doc.events_details.end_date,
          detail: doc.events_details.detail,
          amount: doc.events_details.amount,
          amount_used: doc.events_details.amount_used,
          img: doc.image_m,
          id:doc.id,
          name:doc.name,
          points:doc.points
        })
        this.props.isLoadingDeActive()
      };
    httpClient
    .get(`frontend/api/voucher_c/view/${id}`)
    .then((doc) => {
        setBannerData(doc.data.data)
    })
    .catch((err) => {
        throw err;
    });
    }
    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    handleOk = e => {
      this.setState({
          visible: false,
          usePoint:true
      });
      this.timeCountSecound()
      this.timeCountMin()
    };
    handleCancel = e => {
      this.setState({
          visible: false
      });
    };
    handleSubmit = () => {
        this.setState({
            visible:false
        })
        Swal.fire({
            title: "กำลังตรวจสอบ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            }
          });
        let back = () => this.props.history.push('/profile')
        httpClient.post('frontend/api/voucher_c/redeem',
            {
                id : this.state.id
            }).then(doc=>{
                Swal.close()
                Swal.fire({  
                    title: 'แลกคะแนนสำเร็จ!',  
                    type: 'success',  
                    text: 'ระบบกำลังเปลี่ยนหน้า',
                    timer: 1500,
                    })
                setTimeout(() => {
                    back();
                }, 2000)
        }).catch(err=>{
            throw err
        })
    }
    useCode = () =>{

    }
    render() {
      let { amount,amount_used,name,start_date,end_date,img,detail,points } = this.state
        return (
            <div className='point-wrapper'>
                <Layout>
                    <div>
                        <div className="point-title">
                            <span>ใช้ Point รับรางวัล</span>
                        </div>
                        <div className="product-img">
                        <img className="img-center" src={img} alt="logo" />
                        </div>
                        <div className="product-text">
                            <h1>{name}</h1>
                            {points !== 0 &&<h3>{points} Point</h3>}
                            <h4>
                                ใช้ {points} Point แลกรับ {name} ฟรี!!
                                (จำกัด {amount_used} สิทธิ์/บัญชีผู้ใช้งาน จากทั้งหมด {amount} สิทธิ์)
                            </h4>
                            <h4>
                                ขั้นตอนการแลก
                            </h4>
                            <h4>
                                กดใช้คะแนนเพื่อแลกรางวัล กดตกลงเพื่อยืนยันการใช้สิทธิ์ คูปองของท่านจะอยู่ในหน้าประวัติการแลก Point
                            </h4>
                            <h4>
                                {detail && <div dangerouslySetInnerHTML={{ __html: detail }} />}
                            </h4>
                            <h4>
                                {start_date === end_date ? `แลกได้ถึง ${start_date} หรือจนกว่าสิทธิ์จะเต็มจำนวน` :`แลกได้ตั้งแต่ ${start_date} - ${end_date} หรือจนกว่าสิทธิ์จะเต็มจำนวน`}
                            </h4>
                            
                        </div>
                        {this.state.usePoint === false ?<div className="product-button">
                            <Button marginBottom='15px' marginRight='auto' marginLeft='auto' onClick={()=>this.setState({visible:true})}  fixed={false} block>
                                ใช้ Point แลก
                            </Button>
                        </div>:''}
                        {this.state.usePoint === true?<div className="product-button">
                            <Button marginBottom='15px' marginRight='auto' marginLeft='auto' onClick={()=>this.setState({usePoint:false})} fixed={false} block>
                                ปิด
                            </Button>
                        </div> : ''}
                    </div>
                    <div>
                        <Modal
                        className="point-modal"
                        maskClosable={false}
                        centered
                        visible={this.state.visible}
                        footer={[
                            <div key='ok' className="btn-modal">
                                <Button onClick={()=>this.handleSubmit()} marginLeft='auto' marginRight='auto' block key='ok'>
                                    ตกลง
                                </Button>
                                <Button color="#01A69D" bgColor="transparent" marginLeft='auto' marginRight='auto' block key="cancel" onClick={this.handleCancel}>
                                    ปิด
                                </Button>
                            </div>
                        ]}
                        >
                            <h1 className='text-center'>ยืนยันการแลก Point</h1>
                            <h3 className='text-center'>เมื่อแลก Point แล้วสิทธิ์ของท่านจะอยู่ในหน้า Profile</h3>
                        </Modal>
                    </div>
                </Layout>
            </div>
        )
    }
}

function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading
  };
}
function mapDispatchToProps(dispatch) {
return {
  isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
  isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
  isPoint: (point) => dispatch(ACTION.isPoint(point))
};
}
export default connect(
mapStateToProps,
mapDispatchToProps
)(pointToPrivilegeComponent);