import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../commons/Layout/Layout";
import { Input, Card, Tabs, Avatar, Row, Col, Modal, message,Skeleton,Spin, Empty, Icon } from "antd";
import Buttom from "../../commons/buttonComponent/buttonComponent";
import RenderHtmlText from '../../commons/RenderHtmlText';
import "../styles.css";
import { httpClient } from "../../../utils/HttpClient";
import Swal from "sweetalert2";
/* redux */
import * as ACTION from "../../../store/actions/action";
import { connect } from "react-redux";
/* redux */

const { TabPane } = Tabs;
const { Search } = Input;

class EditFavoriteArtistComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearch:false,
      isEdit: false,
      modal: false,
      myArtist: [],
      unSelect: [],
      onSelect: [],
      unData:[],
      onData:[],
      onSeachSelect:[],
      unSeachSelect:[],
      tap:'1',
      artistLoading : false
    };
  }
  componentDidMount = () => {
    this.props.isLoadingActive();
    this.fetchData();
  };
  callback =  e =>{
    this.setState({tap : e})
  }
  fetchData = async () => {
    let setArtistData = (artisData, artisUnData,status) => {
      this.setState({
        myArtist: artisData,
        onSelect: artisData,
        unSelect: artisUnData,
        unData: artisUnData,
        onData : artisData,
        artistLoading: status ? true : false
      });
      this.props.isLoadingDeActive();
    };
    let id = [];
    let unid = [];
    let artisData = [];
    let artisUnData = [];
    await httpClient
      .get("frontend/api/profile_c/artists/update")
      .then((doc) => {
        let data = doc.data.data;
        data.forEach((doc) => {
          id.push(doc);
        });
      })
      .catch((err) => {throw err});
    await httpClient
      .post("frontend/api/profile_c/artists/all", {
        search: "",
      })
      .then((doc) => {
        let data = doc.data.data;
        data.forEach((doc) => {
          artisData.push(
            Object.assign({
              id: doc.id,
              name: doc.name,
              detail: doc.detail,
              image: doc.image,
            })
          );
        });
      })
      .catch((err) => {throw err});
    await setArtistData(artisData, artisUnData);
    await httpClient
      .post("frontend/api/artists/all", {
        search: "",
      })
      .then(async (doc) => {
        let data = doc.data.data;
        await data.forEach((doc) => {
          unid.push(doc.id);
        });
        await id.forEach((e) => {
          let index = unid.indexOf(e);
          if (index >= 0) {
            unid.splice(index, 1);
          }
        });
        await data.forEach((doc) => {
          for (let i = 0; i < unid.length; i++) {
            if (doc.id === unid[i]) {
              artisUnData.push(
                Object.assign({
                  id: doc.id,
                  name: doc.name,
                  detail: doc.detail,
                  image: doc.image,
                })
              );
            }
          }
        });
        await setArtistData(artisData, artisUnData , true);
      })
      .catch((err) => {throw err});
  };
  handleSearch = (text) =>{
    if(text===''){
      if(this.state.tap === '2'){
        this.setState({unSelect:this.state.unData,isSearch:false})
    }else{
        this.setState({onSelect:this.state.onData,myArtist:this.state.onData,isSearch:false})
    }
    }else{
        if(this.state.tap === '2'){
            text = text.substr(0,20);
            let data = this.state.unSelect.filter((data)=>{
                return data.name.toLowerCase().indexOf(text.toLowerCase()) !== -1
            })
            let results = [];

            results = data.map((content)=>{
                return content
            })
            this.setState({unSeachSelect:results,isSearch:true})
        }else{
            text = text.substr(0,20);
            let data = this.state.onSelect.filter((data)=>{
                return data.name.toLowerCase().indexOf(text.toLowerCase()) !== -1
            })
            let results = [];

            results = data.map((content)=>{
                return content
            })
            this.setState({onSeachSelect:results,myArtist:results,isSearch:true})
        }
    }
}
  selectArtist = (artist) => {
    let selectArtist = this.state.onSelect;
    let unSelectArtist = this.state.unSelect;
    unSelectArtist.splice(unSelectArtist.indexOf(artist), 1);
    selectArtist.push(artist);
    message.success(`ย้าย ${artist.name} ไปยังศิลปินที่เลือก`, 2);
    this.setState({
      unSelect: unSelectArtist,
      onSelect: selectArtist,
      onData: selectArtist,
      unData : unSelectArtist,
      isSearch:false
    });
  };
  unSelectArtist = (artist) => {
    let selectArtist = this.state.onSelect;
    let unSelectArtist = this.state.unSelect;
    selectArtist.splice(selectArtist.indexOf(artist), 1);
    unSelectArtist.push(artist);
    message.success(`ย้าย ${artist.name} ไปยังศิลปินที่ไม่ได้เลือก`, 2);
    this.setState({
      unSelect: unSelectArtist,
      onSelect: selectArtist,
      onData: selectArtist,
      unData : unSelectArtist,
      isSearch:false
    });
  };
  handlesubmit = () =>{
    Swal.fire({
      title: "บันทึกข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    let back = () => {
     this.props.history.goBack()
    }
    let select = this.state.onSelect
    let data = []
    select.forEach(doc=>{
      data.push(+doc.id)
    })
     httpClient.post('frontend/api/profile_c/artists/update',
        {
          artists:data
        }).then(doc=>{
          Swal.fire({  
            title: 'เลือกศิลปินที่ชอบสำเร็จ!',  
            type: 'success',  
            text: 'ระบบกำลังเปลี่ยนหน้า',
            timer: 1500,
            })
            setTimeout(() => {
                back();
            }, 2000)
        }).catch(err=>{throw err})
  }
  render() {
    const { onSelect, unSelect, myArtist , isSearch ,onSeachSelect,unSeachSelect ,artistLoading} = this.state;
    const mySelect = myArtist.length > 0
      ? myArtist.map((artist) => (
        <Col xs={12} key={artist.id}>
          <Card className="card-select">
            <div>
              <Avatar size={90} src={artist.image} />
            </div>
            <Row
              type="flex"
              align="middle"
              justify="center"
              style={{ paddingTop: 5 }}
              onClick={() =>
                this.setState({
                  artistDetail: artist.detail,
                  modal: true,
                })
              }
            >
              <div>
                <span>{artist.name&& artist.name.length > 12 ? (artist.name).substring(0, 12)+"..." : artist.name}</span>
              </div>
              <p
                style={{ paddingTop: 15, paddingLeft: 5 }}
                className="material-icons"
              >
                info
              </p>
            </Row>
          </Card>
        </Col>
      )) : (
      <Col xs={24}>
        <Empty
          imageStyle={{
              height: 60,
          }}
          description={
            <span style={{ color : 'black'}}>ยังไม่มีศิลปินที่เลือก</span>
          }
          image={<Icon style={{ fontSize: '3.5rem', color: '#08c' }} type="inbox" />}
        />
      </Col>
    );
  
    const cardPoint1 = isSearch !== true ? 
      onSelect.length > 0 ?
        onSelect.map((artist) => (
          <Col xs={12} key={artist.id}>
            <Card className="card-select">
              <div onClick={() => this.unSelectArtist(artist)}>
                <Avatar size={90} src={artist.image} />
              </div>
              <Row
                type="flex"
                align="middle"
                justify="center"
                style={{ paddingTop: 5 }}
                onClick={() =>
                  this.setState({
                    artistDetail: artist.detail,
                    modal: true,
                  })
                }
              >
                <div>
                  <span>{artist.name&& artist.name.length > 12 ? (artist.name).substring(0, 12)+"..." : artist.name}</span>
                </div>
                <p
                  style={{ paddingTop: 15, paddingLeft: 5 }}
                  className="material-icons"
                >
                  info
                </p>
              </Row>
            </Card>
          </Col>))
      : (
        <Col xs={24}>
          <Empty
            imageStyle={{
                height: 60,
            }}
            description={
              <span style={{ color : 'black'}}>ยังไม่มีศิลปินที่เลือก</span>
            }
            image={<Icon style={{ fontSize: '3.5rem', color: '#08c' }} type="inbox" />}
          />
        </Col>
      )
     : onSeachSelect.length > 0 ?
     onSeachSelect.map((artist) => (
      <Col xs={12} key={artist.id}>
        <Card className="card-select">
          <div onClick={() => this.unSelectArtist(artist)}>
            <Avatar size={90} src={artist.image} />
          </div>
          <Row
            type="flex"
            align="middle"
            justify="center"
            style={{ paddingTop: 5 }}
            onClick={() =>
              this.setState({
                artistDetail: artist.detail,
                modal: true,
              })
            }
          >
            <div>
              <span>{artist.name&& artist.name.length > 12 ? (artist.name).substring(0, 12)+"..." : artist.name}</span>
            </div>
            <p
              style={{ paddingTop: 15, paddingLeft: 5 }}
              className="material-icons"
            >
              info
            </p>
          </Row>
        </Card>
      </Col>
    )) : (
      <Col xs={24}>
        <Empty
          imageStyle={{
              height: 60,
          }}
          description={
            <span style={{ color : 'black'}}>ไม่มีศิลปินที่ค้นหา</span>
          }
          image={<Icon style={{ fontSize: '3.5rem', color: '#08c' }} type="inbox" />}
        />
      </Col>
    );

    const cardPoint = isSearch !== true ? unSelect.map((artist) => (
      <Col xs={12} key={artist.id}>
        <Card className="card-select">
          <div onClick={() => this.selectArtist(artist)}>
            <Avatar size={90} src={artist.image} />
          </div>
          <Row
            type="flex"
            align="middle"
            justify="center"
            style={{ paddingTop: 5 }}
            onClick={() =>
              this.setState({
                artistDetail: artist.detail,
                modal: true,
              })
            }
          >
            <div>
              <span>{artist.name&& artist.name.length > 12 ? (artist.name).substring(0, 12)+"..." : artist.name}</span>
            </div>
            <p
              style={{ paddingTop: 15, paddingLeft: 5 }}
              className="material-icons"
            >
              info
            </p>
          </Row>
        </Card>
      </Col>
    )):unSeachSelect.map((artist) => (
      <Col xs={12} key={artist.id}>
        <Card className="card-select">
          <div onClick={() => this.selectArtist(artist)}>
            <Avatar size={90} src={artist.image} />
          </div>
          <Row
            type="flex"
            align="middle"
            justify="center"
            style={{ paddingTop: 5 }}
            onClick={() =>
              this.setState({
                artistDetail: artist.detail,
                modal: true,
              })
            }
          >
            <div>
              <span>{artist.name&& artist.name.length > 12 ? (artist.name).substring(0, 12)+"..." : artist.name}</span>
            </div>
            <p
              style={{ paddingTop: 15, paddingLeft: 5 }}
              className="material-icons"
            >
              info
            </p>
          </Row>
        </Card>
      </Col>
    ))
    return (
      <Layout>
        <Skeleton loading={this.props.isLoading}>
        <div className="fav-wrapper">
          <div className="fav-title">
            <h1>ศิลปินที่ชอบ</h1>
          </div>
          <div className="fav-search">
            <Search
              id = 'seach-box'
              onChange = {e => this.handleSearch(e.target.value)}
              placeholder="Search หาชื่อศิลปิน"
              enterButton
            />
          </div>
          {this.state.isEdit === true ? (
            <div className="fav-tap-wrapper">
              <Tabs type="card" defaultActiveKey="1" onChange={this.callback}>
                <TabPane className="point-tap" tab="ศิลปินที่เลือก" key="1">
                  <div className="artist-name-card">
                    <Row>{cardPoint1}</Row>
                  </div>
                </TabPane>
                <TabPane
                  className="point-tap"
                  tab="ศิลปินที่ไม่ได้เลือก"
                  key="2"
                >
                   {artistLoading === false ? <div className="loading-artist">
                      <Row>
                        <Spin />
                      </Row>
                    </div> :
                    <div className="artist-name-card">
                      <Row>{cardPoint}</Row>
                    </div> }
                </TabPane>
              </Tabs>
            </div>
          ) : (
            ""
          )}
          {this.state.isEdit === false ? (
            <div className="fav-tap-wrapper">
              <div className="artist-name-card">
                <Row>{mySelect}</Row>
              </div>
            </div>
          ) : (
            ""
          )}
          <div style={{ marginTop: 20 }}>
            <hr width="100%" size="20" color="#F4F4F4" />
          </div>
          {this.state.isEdit === false ? (
            <div className="fav-buttom">
              <Buttom
                padding ='15px 0px 0px 0px'
                marginBottom="15px"
                marginRight="auto"
                marginLeft="auto"
                width="200px"
                block
                fixed
                onClick={() => this.setState({ isEdit: true })}
              >
                แก้ไขศิลปินที่ชอบ
              </Buttom>
            </div>
          ) : (
            ""
          )}
          {this.state.isEdit === true ? (
            <div className="fav-buttom">
              <Buttom
                padding ='15px 0px 0px 0px'
                marginBottom="15px"
                marginRight="auto"
                marginLeft="auto"
                width="200px"
                block
                fixed
                onClick={this.handlesubmit}
              >
                บันทึก
              </Buttom>
            </div>
          ) : (
            ""
          )}
        </div>
        <Modal
          className="sendcode-modal modal-dialog-scrollable"
          // maskClosable={false}
          centered
          visible={this.state.modal}
          // onOk={this.handleOk}
          onCancel={() => this.setState({ modal: false })}
          footer={[]}
        >
          <h1 className="text-center">
            <b>รายละเอียดศิลปิน</b>
          </h1>
          <div className="text-left modal-body-content">
            <RenderHtmlText content={this.state.artistDetail} />
          </div>
        </Modal>
        </Skeleton>
      </Layout>
    );
  }
}
function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
    isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditFavoriteArtistComponent));
