import React, { Component } from "react";
import Layout from "../../commons/Layout/Layout";
import DetailComponent from "../../commons/profileDetailComponent";
import ArtistComponent from "../../commons/favoriteArtistComponant";
/* redux */
import * as ACTION from "../../../store/actions/action";
import { connect } from "react-redux";
/* redux */
import { httpClient } from "../../../utils/HttpClient";
import {
  Card,
  Avatar,
  // message,
  Tabs,
  Spin,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import Swal from "sweetalert2";
const liff = window.liff;
const { TabPane } = Tabs;
const { Meta } = Card;
class profileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: [],
      history: [],
      pointStart: 8,
      pointLength: 4,
      pointHistoryTotal: 0,
      loading: false,
      hasMore: true,
      walletData: [],
      walletStart: 6,
      walletLength: 3,
      walletHistoryTotal: 0,
      loadingWallet: false,
      hasMoreWallet: true,
      tab: "1",
      profileLoading: true,
    };
  }
  componentDidMount = async () => {
    let pointCount = [];
    let setPointData = (pointData, pointHistoryTotal) => {
      this.setState({
        history: pointData,
        pointLoading: false,
        pointHistoryTotal,
      });
    };
    let walletData = [];
    let setWalletData = (walletData, walletHistoryTotal) => {
      this.setState({ walletData, walletLoading: false, walletHistoryTotal });
    };
    let setProfileData = (data) => {
      this.setState({
        fullname: data.fullname,
        image: data.image,
        gender: data.gender,
        birthdate: data.birthdate,
        email: data.email,
        telephone: data.telephone,
        province:
          data.province && data.province !== null ? data.province.name_th : "",
        customers_points: data.customers_points.points,
        profileLoading: false,
      });
      let userData = Object.assign({
        name: JSON.parse(localStorage.getItem("userData")).name,
        imageUrl: JSON.parse(localStorage.getItem("userData")).imageUrl,
        userId: JSON.parse(localStorage.getItem("userData")).userId,
        email: JSON.parse(localStorage.getItem("userData")).email,
        token: JSON.parse(localStorage.getItem("userData")).token,
        point: data.customers_points.points,
        miles: data.customers_miles_points.points,
      });
      this.props.isPoint(data.customers_points.points);
      localStorage.setItem("userData", JSON.stringify(userData));
      // this.props.isLoadingDeActive();
      if (
        data.email === undefined ||
        data.email === null ||
        data.email === ""
      ) {
        Swal.fire({
          text: "กรุณากรอกอีเมล",
          type: "warning",
          timer: 1500,
        }).then(() => {
          this.props.history.push("/profile/edit");
        });
      }
    };
    await httpClient
      .get("frontend/api/profile_c")
      .then((doc) => {
        setProfileData(doc.data.data);
        httpClient
          .post("frontend/api/profile_c/history/points", {
            length: 8,
            start: 0,
          })
          .then((doc) => {
            let count = doc.data.data;
            count.forEach((doc) => {
              pointCount.push(doc);
            });
            setPointData(pointCount, doc.data.total);
          })
          .catch((err) => {
            throw err;
          });
        httpClient
          .post("frontend/api/profile_c/wallet", {
            length: 6,
            start: 0,
          })
          .then((doc) => {
            let count = doc.data.data;
            count.forEach((doc) => {
              walletData.push(doc);
            });
            setWalletData(walletData, doc.data.total);
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            text: err.response.data.message,
            type: "error",
          }).then(() => {
            liff.closeWindow();
          });
        }
      });
  };
  loadmorePointHistory = () => {
    let { history, pointHistoryTotal, pointStart } = this.state;
    let setPointData = (pointData) => {
      this.setState({
        history: pointData,
        pointStart: pointStart + 4,
        loading: false,
      });
    };
    this.setState({
      loading: true,
    });
    if (history.length >= pointHistoryTotal) {
      //message.warning('Finished loading!');
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }
    httpClient
      .post("frontend/api/profile_c/history/points", {
        length: 4,
        start: pointStart,
      })
      .then((doc) => {
        let count = doc.data.data;
        count.forEach((doc) => {
          history.push(doc);
        });
        setPointData(history);
      })
      .catch((err) => {
        throw err;
      });
  };
  loadmoreWalletHistory = () => {
    let { walletData, walletHistoryTotal, walletStart } = this.state;
    let setPointData = (walletData) => {
      this.setState({
        walletData,
        walletStart: walletStart + 3,
        loadingWallet: false,
      });
    };
    this.setState({
      loadingWallet: true,
    });
    if (walletData.length >= walletHistoryTotal) {
      //message.warning('Finished loading!');
      this.setState({
        hasMoreWallet: false,
        loadingWallet: false,
      });
      return;
    }
    httpClient
      .post("frontend/api/profile_c/wallet", {
        length: 3,
        start: walletStart,
      })
      .then((doc) => {
        let count = doc.data.data;
        count.forEach((doc) => {
          walletData.push(doc);
        });
        setPointData(walletData);
      })
      .catch((err) => {
        throw err;
      });
  };
  callback = (e) => {
    this.setState({
      tab: e,
    });
  };
  onloadMore = () => {
    if (this.state.tab === "1") {
      this.loadmoreWalletHistory();
    } else {
      this.loadmorePointHistory();
    }
  };
  render() {
    const { walletData, history } = this.state;
    const cardPoint1 = walletData.map((point) => (
      <Card
        className="card-history-tab"
        key={point.created_at}
        onClick={() =>
          point.status !== 1
            ? {}
            : this.props.history.push({
                pathname: "/reward/use-privilege",
                state: {
                  id: point.id,
                },
              })
        }
      >
        <Meta
          avatar={
            <Avatar
              style={{ filter: point.status !== 1 ? "grayscale(100%)" : "" }}
              shape="square"
              size={100}
              src={point.image_s}
            />
          }
          title={point.name}
          description={
            <div>
              <p className="text-left">ใช้ได้ถึง {point.expired}</p>
              <h3
                style={{ color: "#FF0000", paddingRight: "10px" }}
                className="text-right"
              >
                {point.status !== 1 && "Used"}
              </h3>
            </div>
          }
        />
      </Card>
    ));
    const cardPoint = history.map((point) => (
      <Card className="card-history-point" key={point.created_at}>
        <Meta
          avatar={
            <Avatar
              style={{ filter: "grayscale(100%)" }}
              shape="square"
              size={64}
              src={point.image_s}
            />
          }
          title={point.name}
          description={
            point.type_points === 0
              ? "-" + point.points + ` คะแนน`
              : "+" + point.points + ` คะแนน`
          }
        />
      </Card>
    ));
    return (
      <div className="profile-infinite-container">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={this.onloadMore}
          hasMore={
            this.state.tab === "1"
              ? !this.state.loadingWallet && this.state.hasMoreWallet
              : !this.state.loading && this.state.hasMore
          }
          useWindow={false}
        >
          <Layout>
            {this.state.profileLoading === false ? (
              <DetailComponent
                fullname={this.state.fullname}
                image={this.state.image}
                gender={this.state.gender}
                birthdate={this.state.birthdate}
                email={this.state.email}
                telephone={this.state.telephone}
                province={this.state.province}
                customers_points={this.state.customers_points}
                profileLoading={false}
              />
            ) : (
              <DetailComponent />
            )}
            <ArtistComponent />
            <div className="point-warpper">
              <Tabs type="card" defaultActiveKey="1" onChange={this.callback}>
                <TabPane className="point-tap" tab="Coupon ของฉัน" key="1">
                  {walletData.length === 0 ? (
                    <div className="empty-point">
                      <img src="/empty.png" alt="empty" />
                      <h4 className="mt-2">NO COUPON AVALIABLE</h4>
                    </div>
                  ) : (
                    <div>
                      <div className="point-history">{cardPoint1}</div>
                      {this.state.loadingWallet && this.state.hasMoreWallet && (
                        <div className="demo-loading-container">
                          <Spin />
                        </div>
                      )}
                    </div>
                  )}
                </TabPane>
                <TabPane
                  className="point-tap"
                  tab="ประวัติการสะสมคะแนน"
                  key="2"
                >
                  {history.length === 0 ? (
                    <div className="empty-point">
                      <img src="/empty.png" alt="empty" />
                      <h4 className="mt-2">NO POINTS COLLECTING HISTORY</h4>
                    </div>
                  ) : (
                    <div>
                      <div className="point-history">{cardPoint}</div>
                      {this.state.loading && this.state.hasMore && (
                        <div className="demo-loading-container">
                          <Spin />
                        </div>
                      )}
                    </div>
                  )}
                </TabPane>
              </Tabs>
            </div>
          </Layout>
        </InfiniteScroll>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
    isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
    isPoint: (point) => dispatch(ACTION.isPoint(point)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(profileComponent);
