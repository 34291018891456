import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
 
class QrReaderComponent extends Component {
  state = {
    result: 'No result'
  }
 
  handleScan = data => {
    setTimeout(() => {
      localStorage.setItem("qrCode",data);
      this.props.history.push('/point/add-point')
    }, 3000);
  }
  handleError = err => {
    console.error(err)
  }
  render() {
    return (
      <div>
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ height:'100%',width: '100%',}}
        />
        <p style={{color:'#4E4E4E',textAlign:"center",margin:15,fontSize:18,fontWeight:400}}>กรุณาเปิดการใช้งานกล้อง </p>
        <p style={{color:'#4E4E4E',textAlign:"center",margin:15,fontSize:18,fontWeight:400}}>(**ในส่วนของ LIFF ยังไม่รองรับการเปิดกล้องและอัพโหลดรูป อาจจะต้องเปิดใช้งานใน Browser อื่น**) </p>
      </div>
    )
  }
}
export default QrReaderComponent