import React, { Component } from "react";
import Layout from "../../commons/Layout/Layout";
import {
  Row,
  Col,
  message,
  Upload,
  Card,
  Input,
  Form,
  Avatar,
  Select,
  Modal,
} from "antd";
import ImageResize from "image-resize";
import Button from "../../commons/buttonComponent/buttonComponent";
import Swal from "sweetalert2";
import "../styles.css";
import "../../../stylesheet/main.css";
/* redux */
import * as ACTION from "../../../store/actions/action";
import { connect } from "react-redux";
/* redux */
import { httpClient } from "../../../utils/HttpClient";
import moment from "moment";
import heic2any from "heic2any";

moment.locale("th");
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const { Option } = Select;
const imageResize = new ImageResize({
  format: "jpg",
  quality: 0.5,
  width: 500,
});
const liff = window.liff;
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/heic" ||
    file.type === "image/heif" ||
    file.type === "image/heif-sequence" ||
    file.type === "image/heic-sequence";

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/HEIC file!");
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    Swal.fire("ขนาดรูปภาพต้องไม่เกิน 10 mb", "", "error");
  }
  return isJpgOrPng && isLt10M;
}
class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gender: "3",
      visible: false,
      dateType: "text",
      imageUrl: "/userDf.png",
      name: "",
      born: "",
      tel: "",
      oldTel: "",
      email: "",
      artis: [],
      artisSelect: [],
      bornPlacehoder: "วันเกิด",
      cityData: [],
      amphurData: [],
      districtData: [],
      image_default: 1,
      artisData: [],
      checkbox: false,
      otp: "",
      artisOleData: [],
      dataFalse: true,
      editPhoneNum: false,
      otpEdit: "",
      imageFile: [],
    };
  }
  componentDidMount() {
    // this.props.isLoadingActive()
    this.fecthData();
  }
  fecthData = async () => {
    let city = [];
    let amphur = [];
    let district = [];
    let setCityData = (data) => {
      this.setState({ cityData: data });
    };
    let checkUser = (data) => {
      this.setState({
        imageUrl: data.image,
        amphur: data.amphure_id,
        city: data.province_id,
        name: data.fullname,
        email: data.email,
        district: data.district_id,
        borntext: moment(data.birthdate * 1000).format("YYYY-MM-DD"),
        born: data.birthdate,
        place: data.address,
        gender: `${data.gender}`,
        tel: data.telephone,
        oldTel: data.telephone,
      });
      city.push(data.province_id);
      amphur.push(data.amphure_id);
      district.push(data.district_id);
    };
    await httpClient
      .get("frontend/api/profile_c/update")
      .then((doc) => {
        checkUser(doc.data.data);
      })
      .catch((err) => {
        throw err;
      });
    let provincesFecth = httpClient
      .get("frontend/api/address/provinces")
      .then((doc) => {
        setCityData(doc.data.data);
      })
      .catch((err) => {
        throw err;
      });
    let setAmpurData = (data) => {
      this.setState({ amphurData: data });
    };
    let amphurFecth =
      city[0] && city[0] !== null
        ? httpClient
            .post("frontend/api/address/amphures", {
              province_id: city[0],
            })
            .then((doc) => {
              setAmpurData(doc.data.data);
            })
            .catch((err) => {
              throw err;
            })
        : () => {};
    let setDisData = (data) => {
      for (let i = 0; i < data.length; i++) {
        if (district[0] === data[i].id) {
          this.setState({
            district: data[i].id,
            postNum: data[i].zip_code,
            districtJson: JSON.stringify(data[i]),
          });
        }
      }
      this.setState({ districtData: data });
    };
    let districtFecth =
      amphur[0] && amphur[0] !== null
        ? httpClient
            .post("frontend/api/address/districts", {
              amphure_id: amphur[0],
            })
            .then((doc) => {
              setDisData(doc.data.data);
            })
            .catch((err) => {
              throw err;
            })
        : () => {};
    let artistCount = [];
    let setArtistData = (artis) => {
      this.setState({ artis });
    };
    let artisFecth = httpClient
      .post("frontend/api/profile_c/artists", {
        length: 4,
        search: "",
      })
      .then((doc) => {
        let data = doc.data.data;
        data.forEach((doc) => {
          artistCount.push(doc.name);
        });
        setArtistData(artistCount);
      })
      .catch((err) => {
        throw err;
      });
    await Promise.all([provincesFecth, amphurFecth, districtFecth, artisFecth]);
    // await this.props.isLoadingDeActive()
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  handleChangePhoto = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      this.props.isLoadingActive();
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        let setImg = (url) => {
          this.setState({
            imageUrl: url,
            imageFile: info.fileList,
          });
          this.props.isLoadingDeActive();
        };
        info.file.type === "image/jpeg" || info.file.type === "image/png"
          ? imageResize
              .play(imageUrl)
              .then(async function (responses) {
                let file = dataURLtoFile(responses, `${Date.now()}.jpg`);
                let formData = new FormData();
                formData.append("image", file);
                httpClient
                  .post("frontend/api/profile_c/upload", formData)
                  .then((result) => {
                    setImg(imageUrl);
                  })
                  .catch((err) => {
                    console.log(err);
                    this.props.isLoadingDeActive();
                  });
              })
              .catch(function (error) {
                console.error(error);
              })
          : fetch(imageUrl)
              .then((res) => res.blob())
              .then((blob) =>
                heic2any({
                  blob,
                  toType: "image/jpeg",
                  quality: 0.3,
                  multiple: true,
                })
              )
              .then((conversionResult) => {
                conversionResult.forEach((image) => {
                  var url = URL.createObjectURL(image);
                  imageResize
                    .play(url)
                    .then(async function (responses) {
                      let file = dataURLtoFile(responses, `${Date.now()}.jpg`);
                      let formData = new FormData();
                      formData.append("image", file);
                      httpClient
                        .post("frontend/api/profile_c/upload", formData)
                        .then((result) => {
                          setImg(url);
                        })
                        .catch((err) => {
                          console.log(err);
                          this.props.isLoadingDeActive();
                        });
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                });
              })
              .catch((e) => {
                console.log(e);
              });
      });
    }
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    this.props.history.push("/profile");
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      let back = () => this.props.history.push("/profile");
      if (!err) {
        if (this.state.tel !== this.state.oldTel) {
          this.otpEdit();
        } else {
          Swal.fire({
            title: "บันทึกข้อมูล",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            },
          });
          httpClient
            .post("frontend/api/profile_c/update", {
              province_id: +this.state.city,
              amphure_id: +this.state.amphur,
              district_id: +this.state.district,
              email: this.state.email,
              fullname: this.state.name,
              address: this.state.place,
              // tel :this.state.tel,
              // zip_code: this.state.postNum === '-' ? 0 :+this.state.postNum,
              gender: +this.state.gender,
              birthdate: +this.state.born,
            })
            .then((result) => {
              Swal.fire({
                title: "แก้ไขข้อมูลส่วนตัวสำเร็จ!",
                type: "success",
                text: "ระบบกำลังเปลี่ยนหน้า",
                timer: 1500,
              });
              setTimeout(() => {
                back();
              }, 2000);
            })
            .catch((err) => {
              throw err;
            });
        }
      } else {
        Swal.close();
        console.log("error: ", err);
        err.name
          ? this.setState({ isName: "close" })
          : this.setState({ isName: "check" });
        err.born
          ? this.setState({ isBorn: "close" })
          : this.setState({ isBorn: "check" });
        err.tel
          ? this.setState({ isTel: "close" })
          : this.setState({ isTel: "check" });
        err.email
          ? this.setState({ isEmail: "close" })
          : this.setState({ isEmail: "check" });
        err.city
          ? this.setState({ isCity: "close" })
          : this.setState({ isCity: "check" });
        err.artis
          ? this.setState({ isArtis: "close" })
          : this.setState({ isArtis: "check" });
        err.place
          ? this.setState({ isPace: "close" })
          : this.setState({ isPace: "check" });
        err.postNum
          ? this.setState({ isPostNum: "close" })
          : this.setState({ isPostNum: "check" });
      }
    });
  };
  success = () => {
    if (this.state.otp !== "") {
      Swal.fire({
        title: "<h4>ยืนยันการลบบัญชีผู้ใช้นี้?</h4>",
        html:
          "เมื่อท่านทำการลบบัญชีผู้ใช้นี้แล้ว<br>" +
          "บัญชีคุณจะยังไม่ถูกลบถาวรจนกว่าจะครบ 3 เดือน<br>" +
          "ถ้าต้องการกู้คืนบัญชีให้ติดต่อ spicyclub@spicydisc.com<br>",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#01A69D",
        confirmButtonText: "ใช่ ลบบัญชีนี้",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "กำลังลบบัญชีผู้ใช้",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Swal.showLoading();
            },
          });
          let back = () => {
            this.setState({
              visible: false,
            });
            this.props.history.push("/profile");
            liff.closeWindow();
          };
          httpClient
            .post("frontend/api/profile_c/delete", {
              otp: this.state.otp,
              ref: this.state.ref,
            })
            .then((doc) => {
              if (doc) {
                Swal.fire({
                  title: "ลบบัญชีสำเร็จ!",
                  type: "success",
                  text: "ระบบกำลังเปลี่ยนหน้า",
                  timer: 1500,
                });
                setTimeout(() => {
                  back();
                }, 2000);
              }
            })
            .catch((err) => {
              throw err;
            });
        }
      });
    } else {
      Swal.fire("กรุณากรอกรหัสที่ได้จากโทรศัพท์มือถือ", "", "error");
    }
  };
  handleFocus = () => {
    this.setState({
      dateType: "date",
    });
  };
  handleBlue = () => {
    this.setState({ bornPlacehoder: this.state.born === "" ? "วันเกิด" : "" });
  };
  handleUpload = () => {
    document.getElementById("btn-upload").click();
  };
  citySelect = async (event) => {
    Swal.fire({
      title: "กำลังค้นหาข้อมูล",
      text: "เขต/อำเภอ",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    await this.setState({ city: event, amphur: "", district: "", postNum: "" });
    this.props.form.setFieldsValue({
      amphur: undefined,
      district: undefined,
    });
    let setAmpurData = (data) => {
      Swal.close();
      this.setState({ amphurData: data });
    };
    await httpClient
      .post("frontend/api/address/amphures", {
        province_id: event,
      })
      .then((doc) => {
        setAmpurData(doc.data.data);
      })
      .catch((err) => {
        throw err;
      });
  };
  amphurSelect = async (event) => {
    Swal.fire({
      title: "กำลังค้นหาข้อมูล",
      text: "แขวง/ตำบล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    await this.setState({ amphur: event, district: "", postNum: "" });
    this.props.form.setFieldsValue({
      district: undefined,
    });
    let setDisData = (data) => {
      Swal.close();
      this.setState({ districtData: data });
    };
    await httpClient
      .post("frontend/api/address/districts", {
        amphure_id: event,
      })
      .then((doc) => {
        setDisData(doc.data.data);
      })
      .catch((err) => {
        throw err;
      });
  };
  districtSelect = async (event) => {
    let data = JSON.parse(event);
    this.setState({
      districtJson: event,
      district: data.id,
      postNum: data.zip_code,
    });
  };
  onSelectCredit = (e) => {
    this.setState({ checkbox: true });
  };
  birthDaySelect = (e) => {
    let text = e.target.value;
    this.setState({ born: moment(text).unix(), borntext: e.target.value });
  };
  handleNewCode = async () => {
    this.setState({
      visible: true,
    });
    Swal.fire({
      title: "กำลังส่ง OTP ไปยังมือถือ",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let setData = (ref) => {
      this.setState({
        ref,
      });
      Swal.close();
    };
    await httpClient
      .post("frontend/api/profile_c/delete/request/otp", {
        telephone: this.state.tel,
        ref: this.state.ref,
      })
      .then((doc) => {
        setData(doc.data.ref);
      })
      .catch((err) => {
        throw err;
      });
  };
  deleteSendOTP = async () => {
    Swal.fire({
      title: "กำลังส่ง OTP ไปยังมือถือ",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let setData = (ref) => {
      this.setState({
        ref,
        visible: true,
      });
      Swal.close();
    };
    await httpClient
      .get("frontend/api/profile_c/delete/send/otp")
      .then((doc) => {
        setData(doc.data.ref);
      })
      .catch((err) => {
        throw err;
      });
  };
  otpEdit = async () => {
    Swal.fire({
      text: `กำลังส่ง OTP ไปยังหมายเลข ${this.state.tel}`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let setData = (refEdit) => {
      this.setState({
        refEdit,
        editPhoneNum: true,
      });
      Swal.close();
    };
    await httpClient
      .post("frontend/api/profile_c/edit_phone_number/send/otp", {
        telephone: this.state.tel,
      })
      .then((doc) => {
        setData(doc.data.ref);
      })
      .catch((err) => {
        throw err;
      });
  };
  handleNewCodeEdit = async () => {
    this.setState({
      editPhoneNum: true,
    });
    Swal.fire({
      text: `กำลังส่ง OTP ไปยังหมายเลข ${this.state.tel}`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let setData = (refEdit) => {
      this.setState({
        refEdit,
      });
      Swal.close();
    };
    await httpClient
      .post("frontend/api/profile_c/edit_phone_number/request/otp", {
        telephone: this.state.tel,
        ref: this.state.refEdit,
      })
      .then((doc) => {
        setData(doc.data.ref);
      })
      .catch((err) => {
        throw err;
      });
  };
  edit = () => {
    Swal.fire({
      title: "บันทึกข้อมูล",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let back = () => this.props.history.push("/profile");
    httpClient
      .post("frontend/api/profile_c/edit_phone_number ", {
        telephone: this.state.tel,
        ref: this.state.refEdit,
        otp: this.state.otpEdit,
      })
      .then((doc) => {
        httpClient
          .post("frontend/api/profile_c/update", {
            province_id: +this.state.city,
            amphure_id: +this.state.amphur,
            district_id: +this.state.district,
            email: this.state.email,
            fullname: this.state.name,
            address: this.state.place,
            // tel :this.state.tel,
            // zip_code: this.state.postNum === '-' ? 0 :+this.state.postNum,
            gender: +this.state.gender,
            birthdate: +this.state.born,
          })
          .then((result) => {
            Swal.fire({
              title: "แก้ไขข้อมูลส่วนตัวสำเร็จ!",
              type: "success",
              text: "ระบบกำลังเปลี่ยนหน้า",
              timer: 1500,
            });
            setTimeout(() => {
              back();
            }, 2000);
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { imageUrl, cityData, amphurData, districtData } = this.state;
    const cityOption = cityData.map((city) => (
      <Option key={city.id} value={city.id}>
        <Row type="flex" align="middle">
          <i className="material-icons">place</i>
          <span style={{ paddingLeft: 15 }}>{city.name_th}</span>
        </Row>
      </Option>
    ));
    const amphurOption = amphurData.map((amphur) => (
      <Option key={amphur.id} value={amphur.id}>
        <Row type="flex" align="middle">
          <i className="material-icons">account_balance</i>
          <span style={{ paddingLeft: 15 }}>{amphur.name_th}</span>
        </Row>
      </Option>
    ));
    const districtOption = districtData.map((district) => (
      <Option key={district.id} value={JSON.stringify(district)}>
        <Row type="flex" align="middle">
          <i className="material-icons">flag</i>
          <span style={{ paddingLeft: 15 }}>{district.name_th}</span>
        </Row>
      </Option>
    ));
    return (
      <Layout>
        <Form className="register-wrapper">
          <Card className="card-register" bordered={false}>
            <div className="avatar__user">
              <Avatar className="avatar-user" size={150} src={imageUrl} />
              <span className="edit-icon" onClick={this.handleUpload}>
                <i className="material-icons">edit</i>
                {getFieldDecorator("upload", {
                  valuePropName: "fileList",
                  getValueFromEvent: this.normFile,
                })(
                  <Upload
                    fileList={this.state.imageFile}
                    name="image"
                    customRequest={dummyRequest}
                    listType="text"
                    beforeUpload={beforeUpload}
                    onChange={this.handleChangePhoto}
                  >
                    <button id="btn-upload" className="upload-button"></button>
                  </Upload>
                )}
              </span>
            </div>
            <div
              style={{ textAlign: "center", marginTop: -15, marginBottom: 25 }}
            >
              <span style={{ fontWeight: 400, fontSize: 14 }}>
                กรอกชื่อและนามสกุลจริง เพื่อใช้ยืนยันการรับรางวัล
              </span>
            </div>
            <Form.Item>
              {getFieldDecorator("name", {
                initialValue: this.state.name,
                rules: [{ required: true, message: "กรุณากรอกชื่อ-นามสกุล" }],
              })(
                <Input
                  name="name"
                  className="input-normal"
                  prefix={<i className="material-icons">account_circle</i>}
                  suffix={<i className="material-icons">{this.state.isName}</i>}
                  minLength={3}
                  type="text"
                  placeholder="  ชื่อ-นามสกุล"
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              )}
            </Form.Item>
            <Form.Item className="gender-from">
              {getFieldDecorator("gender", {
                initialValue: this.state.gender,
                rules: [{ required: true, message: "โปรดเลือกเพศของคุณ" }],
              })(
                <Select
                  className="input-city input-gender"
                  placeholder={
                    <Row type="flex" align="middle">
                      <div
                        style={{ marginTop: -10, marginLeft: -10 }}
                        className="gender-man"
                      ></div>
                      <span
                        style={{
                          paddingLeft: 10,
                          fontSize: 16,
                          color: "#bfbfbf",
                          marginTop: -10,
                          marginLeft: -10,
                        }}
                      >
                        เพศ
                      </span>
                    </Row>
                  }
                  style={{ width: "100%" }}
                  onChange={(e) => this.setState({ gender: e })}
                >
                  <Option value="1">
                    <Row type="flex" align="middle">
                      <div
                        style={{ marginLeft: -7, marginTop: -5 }}
                        className="gender-man"
                      ></div>
                      <span
                        style={{
                          paddingLeft: 10,
                          marginLeft: -7,
                          marginTop: -5,
                        }}
                      >
                        ชาย
                      </span>
                    </Row>
                  </Option>
                  <Option value="2">
                    <Row type="flex" align="middle">
                      <div
                        style={{ marginLeft: -7, marginTop: -5 }}
                        className="gender-women"
                      ></div>
                      <span
                        style={{
                          paddingLeft: 10,
                          marginLeft: -7,
                          marginTop: -5,
                        }}
                      >
                        หญิง
                      </span>
                    </Row>
                  </Option>
                  <Option value="3">
                    <Row type="flex" align="middle">
                      <div
                        style={{ marginLeft: -7, marginTop: -5 }}
                        className="gender-both"
                      ></div>
                      <span
                        style={{
                          paddingLeft: 10,
                          marginLeft: -7,
                          marginTop: -5,
                        }}
                      >
                        ไม่ระบุเพศ
                      </span>
                    </Row>
                  </Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item className="born__form">
              <div className="date-icon"></div>
              {getFieldDecorator("born", {
                initialValue: this.state.borntext,
                rules: [
                  { required: true, message: "วันเดือนปีเกิดของคุณไม่ถูกต้อง" },
                ],
              })(
                <Input
                  name="born"
                  className="input-normal-born w-100"
                  minLength={3}
                  type="date"
                  onClick={() => this.setState({ bornPlacehoder: "" })}
                  onBlur={this.handleBlue}
                  placeholder={this.state.bornPlacehoder}
                  onChange={this.birthDaySelect}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("tel", {
                initialValue: this.state.tel,
                rules: [
                  {
                    max: 10,
                    min: 10,
                    message: "กรุณากรอกเบอร์โทรศัพท์ 10 ตัว",
                  },
                  {
                    pattern: "[0-9]",
                    message: "กรุณากรอกเฉพาะตัวเลขเท่านั้น",
                  },
                  {
                    required: true,
                    message: "หมายเลขโทรศัพท์ของคุณไม่ถูกต้อง",
                  },
                ],
              })(
                <Input
                  name="tel"
                  className="input-normal"
                  prefix={<i className="material-icons">phone</i>}
                  suffix={<i className="material-icons">{this.state.isTel}</i>}
                  minLength={10}
                  maxLength={10}
                  // disabled
                  type="number"
                  placeholder="เบอร์โทร*"
                  onChange={(e) => this.setState({ tel: e.target.value })}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("email", {
                initialValue: this.state.email,
                rules: [{ required: true, message: "กรุณากรอกอีเมล" }],
              })(
                <Input
                  name="email"
                  className="input-normal"
                  prefix={<i className="material-icons">drafts</i>}
                  suffix={
                    <i className="material-icons">{this.state.isEmail}</i>
                  }
                  minLength={3}
                  type="text"
                  placeholder="อีเมล*"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("place", {
                initialValue: this.state.place,
              })(
                <Input
                  name="place"
                  className="input-normal"
                  prefix={<i className="material-icons">alt_route</i>}
                  suffix={<i className="material-icons">{this.state.isPace}</i>}
                  minLength={3}
                  type="text"
                  placeholder="ที่อยู่"
                  onChange={(e) => this.setState({ place: e.target.value })}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("city", {
                initialValue:
                  cityData.length > 0 ? (
                    this.state.city
                  ) : (
                    <Row type="flex" align="middle">
                      <i className="material-icons" style={{ fontSize: 23 }}>
                        place
                      </i>
                      <span
                        style={{
                          fontSize: 16,
                          paddingLeft: 15,
                          color: "#bfbfbf",
                        }}
                      >
                        จังหวัด*
                      </span>
                    </Row>
                  ),
                rules: [{ required: true, message: "โปรดเลือกจังหวัดของคุณ" }],
              })(
                <Select
                  className="input-city"
                  placeholder={
                    <Row type="flex" align="middle">
                      <i className="material-icons" style={{ fontSize: 23 }}>
                        place
                      </i>
                      <span
                        style={{
                          fontSize: 16,
                          paddingLeft: 15,
                          color: "#bfbfbf",
                        }}
                      >
                        จังหวัด*
                      </span>
                    </Row>
                  }
                  style={{ width: "100%" }}
                  onChange={this.citySelect}
                >
                  {cityOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("amphur", {
                initialValue:
                  amphurData.length > 0 ? (
                    this.state.amphur || (
                      <Row type="flex" align="middle">
                        <i className="material-icons" style={{ fontSize: 22 }}>
                          account_balance
                        </i>
                        <span
                          style={{
                            fontSize: 16,
                            paddingLeft: 15,
                            color: "#bfbfbf",
                          }}
                        >
                          เขต/อำเภอ*
                        </span>
                      </Row>
                    )
                  ) : (
                    <Row type="flex" align="middle">
                      <i className="material-icons" style={{ fontSize: 22 }}>
                        account_balance
                      </i>
                      <span
                        style={{
                          fontSize: 16,
                          paddingLeft: 15,
                          color: "#bfbfbf",
                        }}
                      >
                        เขต/อำเภอ*
                      </span>
                    </Row>
                  ),
                rules: [
                  { required: true, message: "โปรดเลือกเขต/อำเภอของคุณ" },
                ],
              })(
                <Select
                  className="input-city"
                  placeholder={
                    <Row type="flex" align="middle">
                      <i className="material-icons" style={{ fontSize: 22 }}>
                        account_balance
                      </i>
                      <span
                        style={{
                          fontSize: 16,
                          paddingLeft: 15,
                          color: "#bfbfbf",
                        }}
                      >
                        เขต/อำเภอ*
                      </span>
                    </Row>
                  }
                  style={{ width: "100%" }}
                  onChange={this.amphurSelect}
                >
                  {amphurOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("district", {
                initialValue:
                  districtData.length > 0 ? (
                    this.state.districtJson || (
                      <Row type="flex" align="middle">
                        <i className="material-icons" style={{ fontSize: 22 }}>
                          flag
                        </i>
                        <span
                          style={{
                            fontSize: 16,
                            paddingLeft: 15,
                            color: "#bfbfbf",
                          }}
                        >
                          แขวง/ตำบล*
                        </span>
                      </Row>
                    )
                  ) : (
                    <Row type="flex" align="middle">
                      <i className="material-icons" style={{ fontSize: 22 }}>
                        flag
                      </i>
                      <span
                        style={{
                          fontSize: 16,
                          paddingLeft: 15,
                          color: "#bfbfbf",
                        }}
                      >
                        แขวง/ตำบล*
                      </span>
                    </Row>
                  ),
                rules: [
                  { required: true, message: "โปรดเลือกแขวง/ตำบลของคุณ" },
                ],
              })(
                <Select
                  className="input-city"
                  placeholder={
                    <Row type="flex" align="middle">
                      <i className="material-icons" style={{ fontSize: 22 }}>
                        flag
                      </i>
                      <span
                        style={{
                          fontSize: 16,
                          paddingLeft: 15,
                          color: "#bfbfbf",
                        }}
                      >
                        แขวง/ตำบล*
                      </span>
                    </Row>
                  }
                  style={{ width: "100%" }}
                  onChange={this.districtSelect}
                >
                  {districtOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("postNum", {
                initialValue: this.state.postNum,
              })(
                <Input
                  className="input-normal"
                  prefix={<i className="material-icons">local_post_office</i>}
                  suffix={
                    <i className="material-icons">{this.state.isPostNum}</i>
                  }
                  disabled
                  type="number"
                  placeholder="รหัสไปรษณีย์"
                  onChange={(e) => this.setState({ postNum: e.target.value })}
                />
              )}
            </Form.Item>
            {/* <Form.Item
              onClick={() => this.props.history.push("/profile/edit-artist")}
            >
              {getFieldDecorator("artis", {
                initialValue: this.state.artis,
                rules: [
                  { required: true, message: "กรุณาเลือกศิลปินที่ชื่นชอบ" },
                ],
              })(
                <Input
                  name="artis"
                  className="input-normal"
                  prefix={<i className="material-icons">library_music</i>}
                  suffix={
                    <i className="material-icons">{this.state.isArtis}</i>
                  }
                  minLength={3}
                  type="text"
                  placeholder="ศิลปินที่ชื่นชอบ"
                />
              )}
            </Form.Item> */}
            <Form.Item style={{ textAlign: "center", marginTop: "30px" }}>
              <Button
                marginRight="auto"
                marginLeft="auto"
                width="100%"
                onClick={this.handleSubmit}
                block
              >
                บันทึก
              </Button>
            </Form.Item>
            <Form.Item style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                bgColor="red"
                marginRight="auto"
                marginLeft="auto"
                width="100%"
                onClick={() =>
                  !this.state.ref ? this.deleteSendOTP() : this.handleNewCode()
                }
                block
              >
                ลบบัญชี
              </Button>
            </Form.Item>
            <Modal
              className="sendcode-modal"
              centered
              visible={this.state.visible}
              // onOk={this.handleOk}
              maskClosable={false}
              onCancel={this.handleCancel}
              footer={[
                <Row className="mt-1">
                  <Button
                    marginLeft="40px"
                    width="80%"
                    onClick={this.success}
                    key="ok"
                    block
                  >
                    ยืนยัน
                  </Button>
                </Row>,
                <Row className="mt-1">
                  <Button
                    border="2px solid"
                    borderColor="#01A69D"
                    marginLeft="40px"
                    color="#01A69D"
                    bgColor="transparent"
                    width="80%"
                    key="again"
                    onClick={this.handleNewCode}
                    block
                  >
                    ส่งคำขอรหัสอีกครั้ง
                  </Button>
                </Row>,
                <Row className="mt-1">
                  <Button
                    border="2px solid"
                    borderColor="#01A69D"
                    marginLeft="40px"
                    color="#01A69D"
                    bgColor="transparent"
                    width="80%"
                    key="again"
                    onClick={this.handleCancel}
                    block
                  >
                    ยกเลิก
                  </Button>
                </Row>,
              ]}
            >
              <h2 className="text-center">
                <b>กรอก OTP เพื่อใช้ยืนยันการลบบัญชีผู้ใช้</b>
              </h2>
              <h3 className="text-center">ทางเราได้ทำการส่งรหัส</h3>
              <h3 className="text-center">ไปยังโทรศัทพ์ของคุณ</h3>
              <h3 className="text-center" style={{ margin: 20 }}>
                *รหัสมีอายุ 3 นาที*
              </h3>
              <Row type="flex" justify="center" align="middle">
                <Col span={12}>
                  <h3>กรอกรหัส 6 หลัก</h3>
                </Col>
                <Col span={12}>
                  <Input
                    onChange={(e) => this.setState({ otp: e.target.value })}
                    className="input-phonecode"
                    name="code"
                    minLength={3}
                    type="number"
                    placeholder="OTP"
                  />
                </Col>
              </Row>
              <Row className="mt-1" type="flex" justify="center" align="middle">
                <Col span={12}>
                  <h3>Ref :</h3>
                </Col>
                <Col span={12}>
                  <Input
                    className="input-phonecode"
                    name="ref"
                    minLength={3}
                    disabled
                    value={this.state.ref}
                    type="text"
                    placeholder="Ref"
                  />
                </Col>
              </Row>
            </Modal>
            <Modal
              className="sendcode-modal"
              centered
              visible={this.state.editPhoneNum}
              // onOk={this.handleOk}
              maskClosable={false}
              onCancel={() => this.setState({ editPhoneNum: false })}
              footer={[
                <Row className="mt-1">
                  <Button
                    marginLeft="40px"
                    width="80%"
                    onClick={this.edit}
                    key="ok"
                    block
                  >
                    ยืนยัน
                  </Button>
                </Row>,
                <Row className="mt-1">
                  <Button
                    border="2px solid"
                    borderColor="#01A69D"
                    marginLeft="40px"
                    color="#01A69D"
                    bgColor="transparent"
                    width="80%"
                    key="again"
                    onClick={this.handleNewCodeEdit}
                    block
                  >
                    ส่งคำขอรหัสอีกครั้ง
                  </Button>
                </Row>,
                <Row className="mt-1">
                  <Button
                    border="2px solid"
                    borderColor="#01A69D"
                    marginLeft="40px"
                    color="#01A69D"
                    bgColor="transparent"
                    width="80%"
                    key="again"
                    onClick={() => {
                      this.setState({ editPhoneNum: false });
                      window.location.reload();
                    }}
                    block
                  >
                    ยกเลิก
                  </Button>
                </Row>,
              ]}
            >
              <h2 className="text-center">
                <b>กรอก OTP เพื่อใช้ยืนยันการแก้ไขบัญชีผู้ใช้</b>
              </h2>
              <h3 className="text-center">ทางเราได้ทำการส่งรหัส</h3>
              <h3 className="text-center">ไปยังโทรศัทพ์ของคุณ</h3>
              <h3 className="text-center" style={{ margin: 20 }}>
                *รหัสมีอายุ 3 นาที*
              </h3>
              <Row type="flex" justify="center" align="middle">
                <Col span={12}>
                  <h3>กรอกรหัส 6 หลัก</h3>
                </Col>
                <Col span={12}>
                  <Input
                    onChange={(e) => this.setState({ otpEdit: e.target.value })}
                    className="input-phonecode"
                    name="code"
                    minLength={3}
                    type="number"
                    placeholder="OTP"
                  />
                </Col>
              </Row>
              <Row className="mt-1" type="flex" justify="center" align="middle">
                <Col span={12}>
                  <h3>Ref :</h3>
                </Col>
                <Col span={12}>
                  <Input
                    className="input-phonecode"
                    name="ref"
                    minLength={3}
                    disabled
                    value={this.state.refEdit}
                    type="text"
                    placeholder="Ref"
                  />
                </Col>
              </Row>
            </Modal>
          </Card>
        </Form>
      </Layout>
    );
  }
}
const EditProfileComponent = Form.create({ name: "EditProfile" })(EditProfile);
function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    isLoadingActive: () => dispatch(ACTION.ISLOADINGACTIVE),
    isLoadingDeActive: () => dispatch(ACTION.ISLOADINGDEACTIVE),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfileComponent);
