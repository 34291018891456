import axios from 'axios'
import join from 'url-join'
import Swal from 'sweetalert2'
const isAbsoluteURLRegex = /^(?:\w+:)\/\//

// const path = window.location.pathname
axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.url = join(`${process.env.REACT_APP_API_HTTP}`, config.url);
  }
  config.headers.Authorization = "Bearer " + JSON.parse(localStorage.getItem("userData")).token;
  // config.timeout = 15000; // 15 Second
  return config;
});

axios.interceptors.response.use((response) => {
            return response
}, error => { 
    // debugger
    console.log('error',error.response)
    if(error.response){
        if (error.code === 'ECONNABORTED'){
            Swal.fire({  
                title: 'หมดเวลาการเชื่อมต่อ', 
                text:'กรุณาลองใหม่อีกครั้ง',
                type: 'error',
                timer: 3000,
                })
        }else if(error.response.status === 401){
            localStorage.clear()
              Swal.fire({  
              title: 'Authorization.', 
              text:'ไม่มีสิทธิ์เข้าถึงข้อมูล', 
              type: 'error',
              timer: 3000,
              })
            window.location.href = '/';
        }else if(error.response.status === 500){
            Swal.fire({  
              title: error.response.data.message,  
              type: 'error',
              timer: 3000,
              })
        }else if(error.response.status === 422){
            Swal.fire({  
                title: error.response.data.message,  
                type: 'error',
                timer: 3000,
                })
        }else if(error.response.status === 400){
            Swal.fire({  
                text: error.response.data.message,  
                type: 'error',
                })
        }  
    } 
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (!error.response) {
      Swal.fire({  
        title: "การเชื่อมต่อผิดพลาด",
        text:'กรุณาเช็คการเชื่อมต่อของท่าน',  
        type: 'error',
        timer: 3000,
        })
      return Promise.reject({
        code: "NOT_CONNECT_NETWORK",
        message: "NETWORK_CONNECTION_MESSAGE",
      });
    }
    return Promise.reject(error);
  }
);

export const httpClient = axios;
