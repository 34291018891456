import React from "react";
import './styles.css'
import styled from "styled-components";

// &:active {
//   background: #01A69D !important;
//   color: #fff !important;
// }
export const ButtonStyle = styled.button`
  &:hover {
    background: #01A69D !important;
    color: #fff !important;
  }
`;

const buttonComponent = ({
  children,
  type,
  fixed,
  className,
  bgColor="",
  border = '0',
  onClick,
  borderRadius = '3px',
  color="#fff",
  disabled,
  block,
  marginLeft='0',
  marginRight='0',
  marginTop='0',
  marginBottom='0',
  width='calc(100% - 30px)',
  aLign='',
  bottom='',
  left='',
  borderColor='',
  padding='',
  background='#ffffff'
}) => {
  return (
    <div
      className='btn-wrapper'
      style={{
        position: `${fixed===true?'fixed':fixed}`,
        bottom,left
      }}
    >
      <div style={{marginLeft:marginLeft,marginRight:marginRight,marginTop,marginBottom,width,padding,background}} className='btn-bottom'>
      <ButtonStyle
        className={`btn ${className} ${block?'btn-block':''}`}
        disabled= {disabled?'disabled':''}
        type={type}
        style={{
        color:color,
        backgroundImage: bgColor === "" ? "linear-gradient(#45E2D9,#01A69D)" : '',
        backgroundColor:  bgColor === "" ?`transparent`:bgColor,
        borderColor:borderColor,
        border: border,
        borderRadius: borderRadius,
        textAlign: aLign === ''?'center' : aLign
        }}
        onClick={event=>{
            if(onClick){
                onClick(event)
            }
        }}
      >
        {children}
      </ButtonStyle>
      </div>
    </div>
  );
};
export default buttonComponent;
