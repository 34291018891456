import React, { Component } from "react";
import Layout from "../../commons/Layout/Layout";
import Button from '../../commons/buttonComponent/buttonComponent'
import '../../../stylesheet/main.css'
import '../styles.css'
import {
    Card,
    Input,
    Form,
  } from "antd";
import { httpClient } from "../../../utils/HttpClient";
import Swal from "sweetalert2";

const { TextArea } = Input;
class contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
        email : localStorage.getItem("userData") &&JSON.parse(localStorage.getItem("userData")).email
    };
  }

  handleSubmit = (e) =>{
    e.preventDefault();
    Swal.fire({
        title: "กำลังส่งอีเมล",
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });
    let back = () => {
      this.props.history.push('/');
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await httpClient.post('frontend/api/sendemail',
        {
            email:this.state.email,
            name : this.state.name,
            description : this.state.comment,
        }).then(doc=>{
            Swal.fire({  
                title: 'ส่งอีเมลสำเร็จ!',  
                type: 'success',  
                text: 'ระบบกำลังเปลี่ยนหน้า',
                timer: 2000,
                })
            setTimeout(() => {
                back();
            }, 2000)
        }).catch(err=>{throw err})
      }})
}
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout>
        <Form className="register-wrapper">
              <Card style={{paddingTop : 0}} bordered={false}>
                <h3>Send e-mail to spicyclub@spicydisc.com</h3>
                <Form.Item className="mt-2">
                  {getFieldDecorator("name", {
                    initialValue: this.state.name,
                    rules: [
                      { required: true, message: "กรุณากรอกชื่อ" },
                    ],
                  })(
                    <Input
                      name="name"
                      size='large'
                      type="text"
                      placeholder="ชื่อ*"
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("email", {
                    initialValue: localStorage.getItem("userData") &&JSON.parse(localStorage.getItem("userData")).email,
                    rules: [
                      { required: true, message: "กรุณากรอกอีเมล" },
                    ],
                  })(
                    <Input
                      name="email"
                      size='large'
                      type="text"
                      disabled
                      placeholder="email"
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("comment", {
                    initialValue: this.state.comment,
                    rules: [
                      { required: true, message: "กรุณากรอกข้อความ" },
                    ],
                  })(
                    <TextArea
                      name="Comment"
                      size='large'
                      rows={8}
                      placeholder="ข้อความ"
                      onChange={(e) => this.setState({ comment: e.target.value })}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                    <Button
                        marginRight="auto"
                        marginLeft="auto"
                        width="100%"
                        onClick={this.handleSubmit}
                        block
                    >
                        Send Email
                    </Button>
                </Form.Item>
            </Card>
        </Form>
      </Layout>
    );
  }
}
const ContactComponent = Form.create({ name: "contacts" })(contact);

export default ContactComponent;
