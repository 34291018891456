import * as ACTION_TYPES from "./action_type";
export const ISLOADINGACTIVE = {
  type: ACTION_TYPES.ISLOADINGACTIVE
};
export const ISLOADINGDEACTIVE = {
  type: ACTION_TYPES.ISLOADINGDEACTIVE
};
export const isPoint = (point) => {
  return {
      type: ACTION_TYPES.ISPOINT,
      payload: point
  }
}